import React from "react";
import Layout from "../../components/Admin/AdminLayout";
import { useLocation } from "react-router-dom";
import TableCandidates from "../../components/Admin/TableCandidates";
import TablePartners from "../../components/Admin/TablePartners";
import RevenueForm from "../../components/Admin/RevenueForm";

const Admin = () => {
  const location = useLocation();
  const fragment = location.hash;

  const param = fragment.substring(1);
  return (
    <Layout>
      {param === "candidates" && <TableCandidates />}
      {param === "revenue" && <RevenueForm />}
      {(!param || param === "partners") && <TablePartners />}
    </Layout>
  );
};

export default Admin;
