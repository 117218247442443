import React from "react";
import { Box, VStack, Link, HStack } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

const SettingsSidebar = () => {
  const location = useLocation();
  const linkWidth = "300px"; // Define a fixed width for the links

  return (
    <Box width="auto" padding={0} height="auto">
      <VStack
        align="start"
        spacing={0}
        background="white"
        p={6}
        borderRadius="12px"
        gap={0}
      >
        <Box width={linkWidth}>
          <HStack
            bg={location.pathname === "/settings" ? "#DCEBFA" : "transparent"}
            borderRadius="8px"
            pl={3}
            pr={3}
            m={0}
          >
            <Link
              as={NavLink}
              to="/settings"
              style={{ textDecoration: "none" }}
              color={location.pathname === "/settings" ? "#016DDA" : "black"}
              paddingY="16px" // Increase the vertical padding
              paddingX="8px"
              borderRadius="8px"
              width="100%"
            >
              Personal Information
            </Link>
          </HStack>
        </Box>
        <Box p={0} width={linkWidth}>
          <HStack
            bg={
              location.pathname === "/settings/notifications"
                ? "#DCEBFA"
                : "transparent"
            }
            borderRadius="8px"
            pl={3}
            pr={3}
            m={0}
          >
            <Link
              as={NavLink}
              to="/settings/notifications"
              style={{ textDecoration: "none" }}
              color={
                location.pathname === "/settings/notifications"
                  ? "#016DDA"
                  : "black"
              }
              paddingY="16px" // Increase the vertical padding
              paddingX="8px"
              borderRadius="8px"
              width="100%"
            >
              Notifications
            </Link>
          </HStack>
        </Box>
        <Box p={0} width={linkWidth}>
          <HStack
            bg={
              location.pathname === "/settings/desired-salary"
                ? "#DCEBFA"
                : "transparent"
            }
            borderRadius="8px"
            pl={3}
            pr={10}
            m={0}
          >
            <Link
              as={NavLink}
              to="/settings/desired-salary"
              style={{ textDecoration: "none" }}
              color={
                location.pathname === "/settings/desired-salary"
                  ? "#016DDA"
                  : "black"
              }
              paddingY="16px" // Increase the vertical padding
              paddingX="8px"
              borderRadius="8px"
              width="100%"
            >
              Desired Salary
            </Link>
          </HStack>
        </Box>
        <Box p={0} width={linkWidth}>
          <HStack
            bg={
              location.pathname === "/settings/change-password"
                ? "#DCEBFA"
                : "transparent"
            }
            borderRadius="8px"
            pl={3}
            pr={10}
            m={0}
          >
            <Link
              as={NavLink}
              to="/settings/change-password"
              style={{ textDecoration: "none" }}
              color={
                location.pathname === "/settings/change-password"
                  ? "#016DDA"
                  : "black"
              }
              paddingY="16px" // Increase the vertical padding
              paddingX="8px"
              borderRadius="8px"
              width="100%"
            >
              Change Password
            </Link>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default SettingsSidebar;