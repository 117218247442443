import React, { useState, useContext, useEffect } from "react";
import { Box, Flex, Text, Image, VStack } from "@chakra-ui/react";
import Skills from "../../components/LoginProcess/Skills";
import JobDetails from "../../components/LoginProcess/JobDetails";
import EnglishTest from "../../components/LoginProcess/EnglishTest";
import IDCheck from "../../components/LoginProcess/IDCheck";
import CVAdding from "../../components/LoginProcess/CVAdding";
import { AuthContext } from "../../context";

const LoginProcess = () => {
  const [step, setStep] = useState(0);
  const [assessments, setAssessments] = useState([]);
  const {
    onboardingStatus,
    userData,
    uploadIdentification,
    uploadCv,
    addSkills,
    addEnglishTestScore,
    addSalariesFullTimeAndPosition,
    getOwnEnglishTestScore,
    validateEnglishTestScore,
    getListOfSkills,
    addAssessments,
    createCvFromLinkedIn,
    fetchUserData,
    loginToken
  } = useContext(AuthContext);

  // Handle switching to next component
  const handleNext = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  // List of components to render
  const steps = [
    <JobDetails
      handleNext={handleNext}
      addSalariesFullTimeAndPosition={addSalariesFullTimeAndPosition}
      userData={userData}
    />,
    <EnglishTest
      handleNext={handleNext}
      addEnglishTestScore={addEnglishTestScore}
      getOwnEnglishTestScore={getOwnEnglishTestScore}
      validateEnglishTestScore={validateEnglishTestScore}
    />,
    <Skills
      handleNext={handleNext}
      addSkills={addSkills}
      getListOfSkills={getListOfSkills}
      addAssessments={addAssessments}
      assessments={assessments}
    />,
    <CVAdding
      handleNext={handleNext}
      createCvFromLinkedIn={createCvFromLinkedIn}
      uploadCv={uploadCv}
    />,
    <IDCheck
      uploadIdentification={uploadIdentification} 
      fetchUserData={fetchUserData}
      loginToken={loginToken}
      />
  ];

  const stepTitles = [
    "Job Details",
    "English Test",
    "Skills",
    "Upload CV",
    "Upload ID",
  ];

  const stepSubtexts = [
    "Provide your job-related details.",
    "Enter your English test scores.",
    "Select your skills.",
    "Upload your CV for review.",
    "Upload your ID for verification.",
  ];

  useEffect(() => {
    const checkUserData = () => {
      switch (onboardingStatus) {
        case "pendingSalarySetup":
          setStep(0);
          break;
        case "pendingEnglishValidation":
          setStep(1);
          break;
        case "pendingSkillsSetup":
          setStep(2);
          break;
        case "pendingResumeSetup":
          setStep(3);
          break;
        default:
          break;
      }
    };

    checkUserData();
  }, [onboardingStatus]);

  if (onboardingStatus === null) {
    return (
      <Flex height="100vh" align="center" justify="center">
      </Flex>
    );
  }

  return (
    <Flex height="100vh">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          flexDirection="column"
        >
          <Flex alignItems="center" justifyContent="center" mt={6} mb={4} p={4}>
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>
          </Flex>
          <Flex flexGrow={1} alignItems="center" justifyContent="center" mt={-8}>
            <VStack spacing={10} mb={6} align="start">
              {stepTitles.map((title, index) => (
                <Flex key={index} alignItems="flex-start" direction="column">
                  <Flex
                    alignItems="flex-start"
                    direction="row"
                    textAlign="left"
                    justifyContent="flex-start"
                    _hover={{ cursor: "pointer" }}
                  >
                    <Box
                      width={index === step ? "20px" : "12px"}
                      height={index === step ? "20px" : "12px"}
                      borderRadius="50%"
                      border="1px solid white"
                      backgroundColor={index === step ? "white" : "transparent"}
                      marginRight={4}
                    />
                    <Flex direction="column" p={0} mt="-8.5px">
                      <Text
                        fontSize={index === step ? 24 : 16}
                        fontWeight={index === step ? 700 : 300}
                        color="white"
                        textAlign="left"
                        p={0}
                        m={0}
                      >
                        {title}
                      </Text>

                      {index === step && (
                        <Text color="white" pl={0} fontWeight={300} fontSize={12}>
                          {stepSubtexts[index]}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </Flex>
        </Box>
      </Box>
      <Flex width={{ base: "100%", md: "70%" }} align="center" justify="center" bg="white" p={8}>
        <Box flex={1} p={4}>
          {steps[step]}
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginProcess;