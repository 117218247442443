import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Icon,
  Badge,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

interface Skill {
  id: string;
  name: string;
  seniority: string | null;
  testId: string;
  publicUrl: string;
  score: number;
  status: string;
  requiredSkills: string[];
  userId: string;
}

type SortField = 'name' | 'seniority' | 'score';
type SortOrder = 'asc' | 'desc';

interface TableSkillsProps {
  skills: Skill[];
}

export const TableSkills = ({ skills }: TableSkillsProps) => {
  const [sortField, setSortField] = useState<SortField>('score');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [sortedSkills, setSortedSkills] = useState<Skill[]>(skills);

  const handleSort = (field: SortField) => {
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);

    const sorted = [...sortedSkills].sort((a, b) => {
      if (field === 'seniority') {
        // Manejar valores nulos en seniority
        if (a[field] === null) return newSortOrder === 'asc' ? 1 : -1;
        if (b[field] === null) return newSortOrder === 'asc' ? -1 : 1;
      }

      if (newSortOrder === 'asc') {
        return (a[field] ?? '') > (b[field] ?? '') ? 1 : -1;
      }
      return (a[field] ?? '') < (b[field] ?? '') ? 1 : -1;
    });

    setSortedSkills(sorted);
  };

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return null;
    return sortOrder === 'asc' ? (
      <Icon as={TriangleUpIcon} w={3} h={3} ml={1} />
    ) : (
      <Icon as={TriangleDownIcon} w={3} h={3} ml={1} />
    );
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'green';
      case 'in_progress':
        return 'yellow';
      case 'pending':
        return 'gray';
      default:
        return 'gray';
    }
  };

  return (
    <Box 
      bg="white" 
      borderRadius="10px" 
      p={4}
      boxShadow="sm"
    >
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr
              bg="#DCEBFA"
              sx={{
                'th:first-of-type': {
                  borderTopLeftRadius: '12px',
                },
                'th:last-of-type': {
                  borderTopRightRadius: '12px',
                },
              }}
            >
              <Th 
                cursor="pointer" 
                onClick={() => handleSort('name')}
                userSelect="none"
                bg="transparent"
              >
                Skills
                <SortIcon field="name" />
              </Th>
              <Th 
                cursor="pointer" 
                onClick={() => handleSort('seniority')}
                userSelect="none"
                bg="transparent"
              >
                Seniority
                <SortIcon field="seniority" />
              </Th>
              <Th 
                cursor="pointer" 
                onClick={() => handleSort('score')}
                userSelect="none"
                isNumeric
                bg="transparent"
              >
                Score
                <SortIcon field="score" />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedSkills.map((skill) => (
              <Tr key={skill.id}>
                <Td>
                  <Box display="flex" alignItems="center" gap={2}>
                    {skill.name}
                    <Badge colorScheme={getStatusColor(skill.status)}>
                      {skill.status}
                    </Badge>
                  </Box>
                </Td>
                <Td>{skill.seniority || 'N/A'}</Td>
                <Td isNumeric>
                  <Box
                    display="inline-block"
                    px={2}
                    py={1}
                    borderRadius="full"
                    bg={skill.score >= 80 ? 'green.100' : skill.score >= 70 ? 'yellow.100' : 'red.100'}
                    color={skill.score >= 80 ? 'green.700' : skill.score >= 70 ? 'yellow.700' : 'red.700'}
                    fontWeight="medium"
                  >
                    {skill.score}%
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
