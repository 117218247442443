import { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { fetchCandidatesData } from "../../../context/partnetContext";

interface Candidate {
  id: string;
  firstName: string;
  lastName: string;
  position: string;
  seniority: string;
  score: number;
  english: number;
  minMonthlySalary: number;
  maxMonthlySalary: number;
  country: string;
  active: boolean;
  userId?: string;
}

export const useTableCandidates = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState<Candidate[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 15;
  const pagesVisited = pageNumber * itemsPerPage;
  const [candidateData, setCandidateData] = useState<Candidate[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

  const token = localStorage.getItem("loginToken");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchCandidatesData(token);
        if ('data' in response) {
          setFilteredData(response.data?.candidates);
          setCandidateData(response.data?.candidates);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getUniqueCountries = (): string[] => {
    const countries = candidateData?.map(item => item.country) || [];
    return ['All Countries', ...new Set(countries)];
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    const filteredResults = candidateData?.filter((item) =>
      item.firstName.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  const handleCountryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    if (selectedCountry === 'All Countries') {
      setFilteredData(candidateData);
    } else {
      const filteredResults = candidateData?.filter((item) =>
        item.country === selectedCountry
      );
      setFilteredData(filteredResults);
    }
  };

  const downloadData = filteredData?.map(({ userId, ...rest }) => rest);

  const getUniqueSalaryRanges = () => {
    const ranges = [
      'All Ranges',
      '0-1000',
      '1000-3000',
      '3000-5000',
      '5000+'
    ];
    return ranges;
  };

  const handleSalaryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const range = e.target.value;
    if (range === 'All Ranges') {
      setFilteredData(candidateData);
    } else {
      const [min, max] = range.split('-').map(Number);
      const filteredResults = candidateData?.filter((item) => {
        if (range === '5000+') {
          return item.minMonthlySalary >= 5000;
        }
        return item.minMonthlySalary >= min && item.minMonthlySalary <= max;
      });
      setFilteredData(filteredResults);
    }
  };

  const handleStatusFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value;
    if (status === 'All') {
      setFilteredData(candidateData);
    } else {
      const isActive = status === 'Active';
      const filteredResults = candidateData?.filter((item) => 
        item.active === isActive
      );
      setFilteredData(filteredResults);
    }
  };

  const handleSort = (field: string) => {
    const newDirection = sortField === field && sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortField(field);
    setSortDirection(newDirection);

    const sortedData = [...filteredData].sort((a, b) => {
      if (newDirection === 'ASC') {
        return a[field] > b[field] ? 1 : -1;
      }
      return a[field] < b[field] ? 1 : -1;
    });

    setFilteredData(sortedData);
  };

  return {
    filteredData,
    pagesVisited,
    itemsPerPage,
    loading,
    error,
    sortField,
    sortDirection,
    isOpen,
    onOpen,
    onClose,
    downloadData,
    handleSearch,
    handleCountryFilter,
    handleSalaryFilter,
    handleStatusFilter,
    handleSort,
    getUniqueCountries,
    getUniqueSalaryRanges
  };
}; 