import React, { useContext } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { AuthContext } from "../../context";

const HeaderCard = () => {
    const { userData } = useContext(AuthContext);

  return (
    <Box borderRadius="12px" background="white" p={6} textAlign="left" mb={4}>
      <Heading fontSize="24px" color="#49424D" fontFamily="Segoe UI">
      {userData ? userData.firstName : "Test"} {userData ? userData.lastName : "Test"} 

      </Heading>
      <Text color="#707887" mt={2} fontFamily="Segoe UI">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. 
      </Text>
    </Box>
  );
};

export default HeaderCard;
