import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Badge,
  Button,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import CsvDownloader from 'react-csv-downloader';
import { getScoreEnglishLevel } from "../../utils/utils";
import { UpDownIcon, ViewIcon } from "@chakra-ui/icons";
import { useTableCandidates } from "./hooks/useTableCandidates";
import { TableSkills } from "./TableSkills";
import { downloadCandidateResume, fetchRevenueData } from "../../context/partnetContext";

interface Candidate {
  id: string;
  position: string;
  skills: Array<{
    id: string;
    name: string;
    seniority: string | null;
    testId: string;
    publicUrl: string;
    score: number;
    status: string;
    requiredSkills: string[];
    userId: string;
  }>;
  score: number;
  english: number;
  minMonthlySalary?: number;
  maxMonthlySalary?: number;
  country: string;
  active: boolean;
  status: 'Cold' | 'Warm' | 'Hot';
  email: string;
}

const TableCandidates: React.FC = () => {
  const {
    filteredData,
    pagesVisited,
    itemsPerPage,
    sortField,
    sortDirection,
    isOpen,
    onOpen,
    downloadData,
    handleSearch,
    handleCountryFilter,
    handleSalaryFilter,
    handleStatusFilter,
    handleSort,
    getUniqueCountries,
    getUniqueSalaryRanges
  } = useTableCandidates();

  const { isOpen: modalIsOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  const [selectedCandidate, setSelectedCandidate] = React.useState<Candidate | null>(null);
  const [revenueData, setRevenueData] = useState<any>(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const token = localStorage.getItem("loginToken");
  const handleViewClick = (candidate: Candidate) => {
    setSelectedCandidate(candidate);
    openModal();
  };

  const defaultStatus = (candidate: Candidate) => {
    return candidate.status || 'Hot';
  };
  const getRevenueData = async () => {
    const response = await fetchRevenueData(token);
    setRevenueData(response);
  };

  useEffect(() => {
    getRevenueData();
  }, []);

  const handleDownloadResume = async (candidateId: string) => {
    try {
      const resume = await downloadCandidateResume(token, candidateId);
      const blob = new Blob([resume], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${candidateId}_resume.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  const calculateSalaryWithRevenue = (salary: number) => {
    if (!revenueData) return salary;
    if (revenueData.revenueType === 'fixed') {
      const fixedRevenue = revenueData.fixedRevenue || 0;
      if (fixedRevenue === 0 || !fixedRevenue) {
        return salary;
      } else {
        return salary + fixedRevenue;
      }
    } else if (revenueData.revenueType === 'percentage') {
      const percentage = Number(revenueData.percentageRevenue) || 0;
      if (percentage === 0 || !percentage) {
        return Number(salary); 
      } else {
        return Number(salary) + Number((Number(salary) * percentage) / 100);
      }
    }
    return salary;
  };

  const displayData = filteredData
    ?.slice(pagesVisited, pagesVisited + itemsPerPage)
    ?.map((item) => (
      <Tr key={item.id}>
        {isMobile ? (
          <Td>
            <Stack spacing={2}>
              <Box>
                <Text fontWeight="bold">Name:</Text>
                <Text fontWeight="bold">Candidate #{item.id.slice(-7)}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Skills:</Text>
                <Text>
                  {item.skills?.slice(0, 2).map(skill => skill.name).join(', ')}
                </Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Score:</Text>
                <Text>{item?.score}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">English:</Text>
                <Text>{getScoreEnglishLevel(item?.english)}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Salary:</Text>
                <Text>
                  {item?.minMonthlySalary && `$${calculateSalaryWithRevenue(item.minMonthlySalary)} / `}
                  {item?.maxMonthlySalary && `$${calculateSalaryWithRevenue(item.maxMonthlySalary)}`}
                </Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Country:</Text>
                <Text>{item?.country}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Status:</Text>
                <Badge
                  color={defaultStatus(item) === "Hot" ? "#00B833" : defaultStatus(item) === "Warm" ? "#FFA500" : "#FF0000"}
                  bg={defaultStatus(item) === "Hot" ? "#E8FFDD" : defaultStatus(item) === "Warm" ? "#FFE5B4" : "#FFDDDD"}
                  padding={"5px 15px"}
                  borderRadius={"4px"}
                >
                  {defaultStatus(item)}
                </Badge>
              </Box>
              <Button 
                size="sm" 
                borderRadius="8px" 
                bg="#016DDA" 
                color="#FFFFFF"
                width="full"
                onClick={() => handleDownloadResume(item.id)}
              >
                <img src="/Icons/download.svg" alt="download" /> Download CV
              </Button>
            </Stack>
          </Td>
        ) : (
          <>
            <Td display="flex" alignItems="center" gap="4px" fontWeight="bold" onClick={() => handleViewClick(item)} cursor="pointer">
              <Button
                w={"32px"}
                h={"32px"}
                size="sm"
                borderRadius="8px"
                bg="#016DDA"
                color="#FFFFFF"
                padding={0}
              >
                <ViewIcon />
              </Button>
               Candidate #{item.id.slice(-7)}
            </Td>
            <Td>{item.skills?.slice(0, 2).map(skill => skill.name).join(', ') || '-'}</Td>
            <Td>{item.score}</Td>
            <Td>{getScoreEnglishLevel(item.english)}</Td>
            <Td>
              {item?.minMonthlySalary && `$${calculateSalaryWithRevenue(item.minMonthlySalary)} / `}
              {item?.maxMonthlySalary && `$${calculateSalaryWithRevenue(item.maxMonthlySalary)}`}
            </Td>
            <Td>{item?.country}</Td>
            <Td>
              <Badge
                color={defaultStatus(item) === "Hot" ? "#00B833" : defaultStatus(item) === "Warm" ? "#FFA500" : "#FF0000"}
                bg={defaultStatus(item) === "Hot" ? "#E8FFDD" : defaultStatus(item) === "Warm" ? "#FFE5B4" : "#FFDDDD"}
                padding={"5px 15px"}
                borderRadius={"4px"}
              >
                {defaultStatus(item)}
              </Badge>
            </Td>
            <Td>
              <Button size="sm" borderRadius="8px" bg="#016DDA" color="#FFFFFF" onClick={() => handleDownloadResume(item.id)}>
                <img src="/Icons/download.svg" alt="download" />
              </Button>
            </Td>
          </>
        )}
      </Tr>
    ));

  return (
    <Box>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        width="100%"
        overflow="auto"
        justifyContent="space-between"
        alignItems={{ base: 'stretch', md: 'center' }}
        spacing={4}
        mb={4}
      >
        <Text fontWeight="bold" fontSize={{ base: '20px', md: '24px' }}>Candidates</Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={2}
          width={{ base: '100%', md: 'auto' }}
        >
          <Input
            placeholder="Find by name..."
            onChange={handleSearch}
            width={{ base: '100%', md: '200px' }}
          />
          <Select
            onChange={handleCountryFilter}
            width={{ base: '100%', md: '200px' }}
            placeholder="Country"
          >
            {getUniqueCountries().map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </Select>
          <Select
            onChange={handleSalaryFilter}
            width={{ base: '100%', md: '200px' }}
            placeholder="Salary range"
          >
            {getUniqueSalaryRanges().map((range) => (
              <option key={range} value={range}>
                {range}
              </option>
            ))}
          </Select>
          <Select
            onChange={handleStatusFilter}
            width={{ base: '100%', md: '200px' }}
            placeholder="Availability"
          >
            <option value="All">All</option>
            <option value="Active">Available</option>
            <option value="Not Active">Not Available</option>
          </Select>
          <CsvDownloader 
            datas={downloadData as any} 
            filename={`candidates-${new Date().toISOString().split('T')[0]}.csv`}
          >
            <Button
              borderRadius="8px"
              bg="#016DDA"
              color="#FFFFFF"
              onClick={onOpen}
              width={{ base: '100%', md: 'auto' }}
            >
              Download Candidates
            </Button>
          </CsvDownloader>
        </Stack>
      </Stack>

      <Box overflowX="auto">
        <Table variant="striped">
          {!isMobile && (
            <Thead>
              <Tr>
                <Th cursor="pointer" _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                  </Box>
                </Th>
                <Th cursor="pointer" onClick={() => handleSort('skills')} _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                    Skills <UpDownIcon transform={sortField === 'skills' ? (sortDirection === 'ASC' ? 'rotate(0deg)' : 'rotate(180deg)') : ''} />
                  </Box>
                </Th>
                <Th cursor="pointer" onClick={() => handleSort('score')} _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                    Score <UpDownIcon transform={sortField === 'score' ? (sortDirection === 'ASC' ? 'rotate(0deg)' : 'rotate(180deg)') : ''} />
                  </Box>
                </Th>
                <Th cursor="pointer" onClick={() => handleSort('english')} _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                    English <UpDownIcon transform={sortField === 'english' ? (sortDirection === 'ASC' ? 'rotate(0deg)' : 'rotate(180deg)') : ''} />
                  </Box>
                </Th>
                <Th cursor="pointer" onClick={() => handleSort('minMonthlySalary')} _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                    Salary Exp. <UpDownIcon transform={sortField === 'minMonthlySalary' ? (sortDirection === 'ASC' ? 'rotate(0deg)' : 'rotate(180deg)') : ''} />
                  </Box>
                </Th>
                <Th cursor="pointer" onClick={() => handleSort('country')} _hover={{ opacity: 0.8 }}>
                  <Box display="flex" gap="4px" alignItems="center">
                    Country <UpDownIcon transform={sortField === 'country' ? (sortDirection === 'ASC' ? 'rotate(0deg)' : 'rotate(180deg)') : ''} />
                  </Box>
                </Th>
                <Th>Status</Th>
                <Th>CV</Th>
              </Tr>
            </Thead>
          )}
          <Tbody>{displayData}</Tbody>
        </Table>
      </Box>

      <Modal isOpen={modalIsOpen} onClose={closeModal} size={isMobile ? "full" : "4xl"}>
        <ModalOverlay />
        <ModalContent margin={isMobile ? 0 : 'auto'}>
          <ModalCloseButton />
          <ModalBody mt={8}>
            {selectedCandidate && (
              <Box>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box>
                    <Text fontSize={20} fontWeight="bold">Candidate #{selectedCandidate.id.slice(-7)}</Text>
                    <Text fontSize={14}>{selectedCandidate.country}</Text>
                  </Box>
                  <Text>
                    {selectedCandidate.minMonthlySalary && `$${calculateSalaryWithRevenue(selectedCandidate.minMonthlySalary)} - `}
                    {selectedCandidate.maxMonthlySalary && `$${calculateSalaryWithRevenue(selectedCandidate.maxMonthlySalary)}`}
                  </Text>
                </Box>
                <Box my={4} fontSize={14} color="#666666">
                  Un diseñador de interfaces con más de tres años de experiencia, especializado en la creación de experiencias digitales que no solo son visualmente atractivas, sino también altamente funcionales y centradas en el usuario. Mi carrera profesional ha estado marcada por mi habilidad para trabajar en equipo y mi organización laboral excepcional, lo que me ha permitido liderar proyectos con eficacia y alcanzar los objetivos propuestos consistentemente.
                  <br/>
                  Mi expertise técnico incluye un dominio avanzado de herramientas como Adobe XD y Figma, además de un conocimiento sólido en principios de diseño, usabilidad y accesibilidad. Mi experiencia en Adobe Photoshop e Illustrator me ha facilitado agilizar el proceso de vectorizado y la creación de interfaces resaltantes.
                  <br/>
                  En mi trayectoria freelance, he gestionado con éxito más de 118 proyectos, manteniendo una puntuación perfecta de 5 estrellas por mi compromiso inquebrantable con la calidad y la satisfacción del cliente. Mi dedicación al detalle y mi capacidad para anticipar las necesidades del usuario final han sido claves en mi éxito, permitiéndome entregar soluciones que no solo satisfacen, sino que deleitan a quienes las usan.
                </Box>
                <TableSkills skills={selectedCandidate.skills} />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => window.open(`mailto:${selectedCandidate?.email}`)}>
              Contact
            </Button>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TableCandidates;
