import React from "react";
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import SettingsSidebar from "../../components/SettingsSidebar";
import SettingsHeader from "../../components/SettingsHeader";

const SettingsLayout = () => {
  return (
    <Box display="flex" flexDirection="column" m={0} p={0}>
      <Box display="flex" flexGrow={1} m={0} p={0}>
        <SettingsSidebar />
        <Box flexGrow={1} backgroundColor="#F5F5F5" m={0} pl={8} gap={6}>
          <SettingsHeader />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsLayout;
