import axios from "axios";
import notify from "../utils/notify";

  // Function to fetch user candidates
 export const fetchCandidatesData = async (token) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/admin/candidates`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error('Error fetching candidates:', error);
      return notify("error", "Could not fetch data. Please try again.");
    }
  };

    // Function to fetch user partners
 export const fetchPartnersData = async (token) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/partners`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('Error fetching partners:', error);
    return notify("error", "Could not fetch data. Please try again.");
  }
};
// Function to register a new partner
export const registerPartner = async (partnerData, token) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/partners`,
      partnerData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { message } = response.data;
    return notify("success", message); 
  } catch (error) {
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => notify("error", err.msg));
    } else if (error.response && error.response.data.message) {
      return notify("error", error.response.data.message);
    } else {
      notify(
        "error",
        "An unexpected error occurred. Please try again."
      );
    }
    throw error;
  }
};

// Function to delete a partner by id
export const deletePartner = async (token, userId) => {
  try {
    const response = await axios.delete(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/users/deleteAccount`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          userId,
        },
      }
    );

    const { message } = response.data;
    return notify("success", message);
  } catch (error) {
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => notify("error", err.msg));
    } else if (error.response && error.response.data.message) {
      return notify("error", error.response.data.message);
    } else {
      notify("error", "An unexpected error occurred. Please try again.");
    }
    throw error;
  }
};

// Function to change partner status
export const changePartnerStatus = async (token, userId, active) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/users/changeUserActiveStatus`,
      {
        userId,
        active,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { message } = response.data;
    return notify("success", message);
  } catch (error) {
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => notify("error", err.msg));
    } else if (error.response && error.response.data.message) {
      return notify("error", error.response.data.message);
    } else {
      notify("error", "An unexpected error occurred. Please try again.");
    }
    throw error;
  }
};

// Function to download candidate resume by id
export const downloadCandidateResume = async (token, candidateId) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/resumes/${candidateId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error downloading resume:', error);
    return notify("error", "Could not download resume. Please try again.");
  }
};

// Function to fetch revenue data
export const fetchRevenueData = async (token) => {
  try {
    const response = await axios.get(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/revenue`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching revenue data:', error);
    return notify("error", "Could not fetch revenue data. Please try again.");
  }
};

// Function to update revenue data
export const updateRevenueData = async (token, revenueData) => {
  try {
    const response = await axios.put(
      `${import.meta.env.VITE_BACKEND_URL}/api/admin/updateRevenue`, revenueData, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating revenue data:', error);
    return notify("error", "Could not update revenue data. Please try again.");
  }
};