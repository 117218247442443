import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Badge,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Select,
  useBreakpointValue,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import CsvDownloader from 'react-csv-downloader';
import { UpDownIcon } from "@chakra-ui/icons";
import { useTablePartners } from "./hooks/useTablePartners";

const TablePartners: React.FC = () => {
  const {
    isOpen,
    onOpen,
    onClose,
    filteredData,
    statusFilter,
    sortConfig,
    onSubmit,
    handleDelete,
    handleChangeStatus,
    handleSearch,
    handleStatusFilter,
    handleSort,
    downloadData
  } = useTablePartners();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box overflowX="auto">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ base: 'stretch', md: 'center' }}
        width="100%"
        mb={4}
      >
        <Heading fontWeight="bold" fontSize={{ base: '20px', md: '24px' }}>Partners</Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={2}
          width={{ base: '100%', md: 'auto' }}
        >
          <Select
            value={statusFilter}
            onChange={handleStatusFilter}
            width={{ base: '100%', md: '150px' }}
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </Select>
          <Input
            placeholder="Find by name..."
            onChange={handleSearch}
            width={{ base: '100%', md: '200px' }}
          />
          <CsvDownloader 
            datas={downloadData as any} 
            filename={`partners-${new Date().toISOString().split('T')[0]}.csv`}
          >
            <Button
              borderRadius="8px"
              bg="#016DDA"
              color="#FFFFFF"
              width={{ base: '100%', md: 'auto' }}
            >
              Download Partners
            </Button>
          </CsvDownloader>
          <Button
            borderRadius="8px"
            bg="#016DDA"
            color="#FFFFFF"
            onClick={onOpen}
            width={{ base: '100%', md: 'auto' }}
          >
            Add New Partner +
          </Button>
        </Stack>
      </Stack>

      <Table variant="striped">
        <Thead>
          <Tr>
            <Th 
              cursor="pointer" 
              onClick={() => handleSort('firstName')}
              _hover={{ opacity: 0.8 }}
            >
              <Box display={"flex"} gap="4px" alignItems={"center"}> 
                Name 
                <UpDownIcon 
                transform={
                  sortConfig.direction === 'desc' 
                    ? 'rotate(180deg)' 
                    : 'none'
                  }
                />
              </Box>
            </Th>
            <Th 
              cursor="pointer" 
              onClick={() => handleSort('email')}
              _hover={{ opacity: 0.8 }}
            >
              <Box display={"flex"} gap="4px" alignItems={"center"}>
                Email 
                <UpDownIcon
                  transform={
                    sortConfig.direction === 'desc' 
                      ? 'rotate(180deg)' 
                      : 'none'
                    }
                  />
              </Box>
            </Th>
            <Th>State</Th>
            <Th textAlign="right">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.map((partner) => (
            <Tr key={partner.id}>
              <Td>{partner.firstName} {partner.lastName}</Td>
              <Td>{partner.email}</Td>
              <Td>
          {partner.active ? (
            <Badge
              color="#00B833"
              bg="#E8FFDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Active
            </Badge>
          ) : (
            <Badge
              color="#FF0000"
              bg="#FFDDDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Not Active
            </Badge>
          )}
        </Td>
        <Td>
          <HStack display="flex" justifyContent="end" wrap="wrap" gap="6">
            {partner.active ? (
              <Button
                size="sm"
                borderRadius="8px"
                borderColor="#016DDA"
                border="1px"
                bg="white"
                color="#016DDA"
                onClick={() => handleChangeStatus(partner.id, false)}
              >
                Deactivate
              </Button>
            ) : (
              <Button size="sm" borderRadius="8px" bg="#DCEBFA" color="#016DDA" onClick={() => 
              handleChangeStatus(partner.id, true)}>
                Activate
              </Button>
            )}
            <Button onClick={() => handleDelete(partner.id)} size="sm" borderRadius="8px" 
              bg="#016DDA" color="#FFFFFF">
                  Delete
                </Button>
              </HStack>
            </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "full" : "xl"}>
        <ModalOverlay />
        <ModalContent margin={isMobile ? 0 : 'auto'}>
          <ModalHeader>Add New Partner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontSize="sm">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              id erat massa. Vestibulum sit amet ante metus. Ut in cursus dui,
              nec mattis ante. Pellentesque ornare varius nisl et pretium.
            </Box>
            <Stack
              spacing={4}
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              marginTop="1.5em"
            >
              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <FormControl isRequired>
                  <Input
                    {...register("firstName", { required: true })}
                    placeholder="First Name"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>

                <FormControl isRequired>
                  <Input
                    {...register("lastName", { required: true })}
                    placeholder="Last Name"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <FormControl isRequired>
                  <Input
                    {...register("email", { required: true })}
                    placeholder="Email"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={2}
              width="100%"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                borderRadius="8px"
                bg="#016DDA"
                color="#FFFFFF"
                onClick={handleSubmit(onSubmit)}
                width={{ base: '100%', md: 'auto' }}
              >
                Add
              </Button>
              <Button
                onClick={onClose}
                borderRadius="8px"
                bg="#DCEBFA"
                color="#016DDA"
                width={{ base: '100%', md: 'auto' }}
              >
                Close
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TablePartners;
