import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Container,
  Heading,
  Text,
  useBreakpointValue,
  Flex,
  Image
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { changePartnerPassword } from '../../context/partnetContext';
import { useNavigate } from 'react-router-dom';
import notify from '../../utils/notify';

const ChangePartnerPass: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password.length < 6) {
      notify('error', 'Password must be at least 6 characters long');
      return;
    }

    if (password !== confirmPassword) {
      notify('error', 'Passwords do not match');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('loginToken');
      if (!token) {
        notify('error', 'You must be logged in to change your password');
        navigate('/login');
        return;
      }

      await changePartnerPassword(password, confirmPassword, token);
      notify('success', 'Password changed successfully');
      navigate('/partner'); // o la ruta que corresponda
    } catch (error) {
      notify('error', 'Error changing password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
        <Box
          height="100vh"
          width="100vw"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <Box display="flex" alignItems="center" justifyContent="center" gap={10} flexDirection={'column'}>
                <Flex alignItems="center">
                    <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
                    <Text fontSize="xl" color="white">MyTeamAbroad</Text>
                </Flex>
                <Box
                    py={{ base: '8', sm: '12' }}
                    px={{ base: '4', sm: '10' }}
                    bg={isMobile ? 'transparent' : 'white'}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <VStack spacing="6">
                    <Heading size="lg">Change Password</Heading>
                    <Text color="gray.600" textAlign="center">
                        Please enter your new password below
                    </Text>

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <VStack spacing="6" width="100%">
                        <FormControl isRequired>
                            <FormLabel>New Password</FormLabel>
                            <InputGroup>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter new password"
                                borderRadius="8px"
                                border="1px solid #E5E6E6"
                                background="white"
                                h={12}
                            />
                            <InputRightElement h="100%">
                                <IconButton
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                variant="ghost"
                                onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel>Confirm New Password</FormLabel>
                            <InputGroup>
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm new password"
                                borderRadius="8px"
                                border="1px solid #E5E6E6"
                                background="white"
                                h={12}
                            />
                            <InputRightElement h="100%">
                                <IconButton
                                aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                                icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                                variant="ghost"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                />
                            </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <Button
                            type="submit"
                            colorScheme="blue"
                            width="100%"
                            isLoading={isLoading}
                            loadingText="Changing password..."
                            bg="#016DDA"
                            color="#FFFFFF"
                            borderRadius="8px"
                            h={12}
                            _hover={{
                            bg: '#0156AE'
                            }}
                        >
                            Change Password
                        </Button>
                        </VStack>
                    </form>
                    </VStack>
                </Box>
            </Box>
        </Box>
  );
};

export default ChangePartnerPass; 