import React, { useEffect, useState } from "react";
import { fetchRevenueData, updateRevenueData } from "../../context/adminContext";
import {
    FormControl,
    FormLabel,
    Select,
    Input,
    Button,
    Stack,
} from "@chakra-ui/react";

const RevenueForm = () => {
    const token = localStorage.getItem("loginToken");
    const [revenueType, setRevenueType] = useState("fixed");
    const [amount, setAmount] = useState("");
    const [revenueData, setRevenueData] = useState({
        fixedRevenue: "",
        percentageRevenue: "",
        revenueType: "fixed",
    });

    const getRevenueData = async () => {
        const data = await fetchRevenueData(token);
        setRevenueData(data);
        setRevenueType(data.revenueType);
        setAmount(data.revenueType === "fixed" ? data.fixedRevenue : data.percentageRevenue);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        updateRevenueData(token, { revenueType, percentageRevenue: revenueType === "fixed" ? revenueData.fixedRevenue : amount, fixedRevenue: revenueType === "percentage" ? revenueData.fixedRevenue : amount });
    };

    useEffect(() => {
        getRevenueData();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel htmlFor="revenueType">Revenue Type:</FormLabel>
                    <Select
                        id="revenueType"
                        value={revenueType}
                        onChange={(e) => {
                            setRevenueType(e.target.value);
                            setAmount(e.target.value === "fixed" ? revenueData.fixedRevenue : revenueData.percentageRevenue);
                        }}
                    >
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel htmlFor="amount">Amount:</FormLabel>
                    <Input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        min={revenueType === "percentage" ? 0 : undefined}
                        max={revenueType === "percentage" ? 100 : undefined}
                        required
                    />
                </FormControl>

                <Button type="submit" colorScheme="blue">
                    Submit
                </Button>
            </Stack>
        </form>
    );
};

export default RevenueForm;
