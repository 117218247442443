import { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SignupConfirmation from "./pages/SignupConfirmation";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import LoginProcess from "./pages/LoginProcess";
import HomeLayout from "./pages/HomeLayout";
import { AuthContext } from "./context";
import PersonalInformation from "./pages/PersonalInformation";
import SettingsLayout from "./pages/SettingsLayout";
import Notifications from "./pages/Notifications";
import ChangePassword from "./pages/ChangePassword";
import DesiredSalary from "./pages/DesiredSalary";
import Admin from "./pages/Admin";
import PartnerPage from "./pages/Partners";
import AdminLogin from "./pages/AdminLogin";
import PartnerLogin from "./pages/PartnerLogin";
import ChangePartnerPass from "./pages/PartnerLogin/ChangePartnerPass";

function App() {
  const { loginToken, userData } = useContext(AuthContext);
  const role = localStorage.getItem("role");
  const isAdmin = role === "admin";
  const isPartner = role === "partner";
  const isNormalUser = !isAdmin && !isPartner;

  const getHomeRoute = () => {
    if (isAdmin) return "/admin";
    if (isPartner) return "/partner";
    if (userData?.signupStatus === "completed") return "/home";
    return "/";
  };

  const publicRoutes = (
    <>
      <Route path="/" element={<Login />} />
      <Route path="/__admin__" element={<AdminLogin />} />
      <Route path="/login/partner" element={<PartnerLogin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup-confirmation/:email" element={<SignupConfirmation />} />
      <Route path="/verify-email/:email/:emailCode" element={<VerifyEmail />} />
    </>
  );

  const adminRoutes = (
    <>
      <Route path="/" element={<Navigate to="/admin" />} />
      <Route path="/admin/*" element={<Admin />} />
    </>
  );
  const passwordSetted = localStorage.getItem("passwordSetted") === "true";
  const partnerRoutes = (
    <>
      <Route path="/" element={<Navigate to={passwordSetted ? "/partner" : "/change-password"} />} />
      <Route path="/partner/*" element={passwordSetted ? <PartnerPage /> : <Navigate to="/change-password" />} />
      <Route path="/change-password" element={<ChangePartnerPass />} />
    </>
  );

  console.log(userData)

  const userRoutes = (
    <>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path="settings" element={<SettingsLayout />}>
          <Route index element={<PersonalInformation />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="desired-salary" element={<DesiredSalary />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
      </Route>
    </>
  );

  // Rutas de proceso de login
  const loginProcessRoutes = (
    <Route path="/*" element={<LoginProcess />} />
  );

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {!loginToken && publicRoutes}
          {loginToken && isAdmin && adminRoutes}
          {loginToken && isPartner && partnerRoutes}
          {loginToken && isNormalUser && userData?.signupStatus === "completed" && userRoutes}
          {loginToken && !role && userData?.signupStatus === "completed" && userRoutes}
          {loginToken && (!userData?.signupStatus || userData?.signupStatus !== "completed") && loginProcessRoutes}

          <Route path="*" element={
            <Navigate to={getHomeRoute()} replace />
          } />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
