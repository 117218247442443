export function getScoreEnglishLevel(score) {
    if (score < 0 || score > 100) {
      return 'Invalid score';
    }
    if (score <= 20) {
      return 'pre-A1';
    } else if (score <= 30) {
      return 'A1 Beginner';
    } else if (score <= 40) {
      return 'A2';
    } else if (score <= 50) {
      return 'B1';
    } else if (score <= 60) {
      return 'B2';
    } else if (score <= 70) {
      return 'C1';
    } else {
      return 'C2';
    }
  }