import React, { useCallback, memo } from "react";
import { Box, Button, Select, Textarea, Input } from "@chakra-ui/react";
import { updateCompanyData } from "../../../context/partnetContext";
import { EditIcon } from "@chakra-ui/icons";

const CompanyNameEditor = memo(({ 
  isEditing, 
  name, 
  onNameChange, 
  onSubmit, 
  onEdit 
}: { 
  isEditing: boolean;
  name: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onEdit: () => void;
}) => (
  <Box display="flex" gap="8px" alignItems="center">
    {isEditing ? (
      <>
        <Input
          value={name}
          onChange={onNameChange}
          placeholder="Enter company name"
          size={["md", "lg"]}
          width={["150px", "200px"]}
        />
      </>
    ) : (
      <>
        <Box fontSize={["16px", "20px"]} fontWeight="bold">
          {name}
        </Box>
        <Button
          size="sm"
          variant="ghost"
          onClick={onEdit}
        >
          <EditIcon />
        </Button>
      </>
    )}
  </Box>
));

CompanyNameEditor.displayName = 'CompanyNameEditor';

export const Customize = memo(({ companyData, fetchCompany }: { companyData: any, fetchCompany: () => void }) => {
  const [isEditingName, setIsEditingName] = React.useState(!companyData?.name);
  const [companyName, setCompanyName] = React.useState(companyData?.name || '');
  const [companyDescription, setCompanyDescription] = React.useState(companyData?.description || '');
  const token = localStorage.getItem("loginToken");

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setIsEditingName(false);
    updateCompanyData(token, { companyName, description: companyDescription });
    fetchCompany();
  }, [token, companyName, companyDescription, fetchCompany]);

  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCompanyDescription(e.target.value);
  }, []);

  return (
    <Box display="flex" gap={["16px", "24px"]} justifyContent="center" w="100%">
      <Box
        display="flex"
        gap={["16px", "24px"]}
        justifyContent="center"
        flexDirection="column"
        w="100%"
        maxW="850px"
        px={["16px", "0"]}
      >
        <Box
          display="flex"
          padding={["16px", "24px"]}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
        >
          <Box display="flex" flexDirection="column" gap="15px">
            <Box fontSize={24} fontWeight="bold">
              Customize CVs
            </Box>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti perspiciatis adipisci praesentium repudiandae debitis. Consequuntur quasi unde aut dicta sunt fuga ducimus magni doloribus, quae necessitatibus nisi, rerum eveniet animi.
            </p>
          </Box>
        </Box>
        <Box
          display="flex"
          padding={["16px", "24px"]}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
        >
          <Box
            marginBottom="2em"
            justifyContent="space-between"
            display="flex"
            flexDirection={["column", "row"]}
            alignItems={["flex-start", "center"]}
            gap="16px"
            w="100%"
          >
            <Box display="flex" gap={6} flexDirection={["column", "row"]} alignItems={["flex-start", "center"]}>
              <Box
                w={[50, 75]}
                h={[50, 75]}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="4px"
                bg="#016DDA"
              >
                <img src="/public/images/logo-min-white.svg" alt="logo" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap="6px"
                alignItems="flex-start"
              >
                <CompanyNameEditor
                  isEditing={isEditingName}
                  name={companyName}
                  onNameChange={handleNameChange}
                  onSubmit={handleSubmit}
                  onEdit={() => setIsEditingName(true)}
                />
                <Button borderRadius="8px" bg="#DCEBFA" color="#016DDA">
                  Change Logo
                </Button>
              </Box>
            </Box>
            <Select 
              w={["100%", "230px"]} 
              placeholder="Company Main Color"
            >
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </Select>
          </Box>
          <Textarea
            onChange={handleDescriptionChange}
            placeholder="About Company"
            h={300}
            value={companyDescription}
            w="100%"
          />
          <Box
            marginTop="2em"
            display="flex"
            w="100%"
            justifyContent="flex-end"
            gap="8px"
            flexDirection={["column", "row"]}
          >
            <Button
              type="submit"
              mr={[0, 3]}
              borderRadius="8px"
              bg="#016DDA"
              color="#FFFFFF"
              onClick={handleSubmit}
              w={["100%", "auto"]}
            >
              Save Changes
            </Button>
            <Button 
              borderRadius="8px" 
              bg="#DCEBFA" 
              color="#016DDA"
              w={["100%", "auto"]}
            >
              Discard Changes
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

Customize.displayName = 'Customize';
