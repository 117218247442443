import React, { useReducer, useEffect, createContext } from "react";
import axios from "axios";
import { AuthReducer } from "./reducer"; // Ensure this is set up to handle authentication-related actions
import notify from "../utils/notify"; // Import your notify function
import { set } from "react-hook-form";

// Initial state for the context
const initialState = {
  loginToken: null,
  isAdmin: false,
  isPartner: false,
  userData: null,
  onboardingStatus: null,
};

// Create the context
export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Set the login token in state and local storage
  const setLoginToken = (loginToken) => {
    dispatch({
      type: "SET_LOGIN_TOKEN",
      payload: loginToken,
    });
  };
  const setIsAdmin = (isAdmin) => {
    dispatch({
      type: "SET_IS_ADMIN",
      payload: isAdmin,
    });
  };
  const setIsPartner = (isPartner) => {
    dispatch({
      type: "SET_IS_PARTNER",
      payload: isPartner,
    });
  };
  // Logout function
  const logout = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("role");
    setLoginToken(null);
    clearState();
    return notify("success", "Logged out successfully");
  };

  const setOnboardingStatus = (onboardingStatus) => {
    dispatch({
      type: "SET_ONBOARDING_STATUS",
      payload: onboardingStatus,
    });
  };

  const clearState = () => {
    dispatch({
      type: "CLEAR_STATE",
    });
  };

  // Login function
  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/login`,
        { email, password }
      );

      // Save the token in local storage and update the context
      const { message, data } = response.data;
      const { token, onboardingStatus } = data;

      // Check if token has a value
      if (!token) {
        throw new Error("Token is missing from the response");
      }

      localStorage.setItem("loginToken", token);
      setLoginToken(token);
      setOnboardingStatus(onboardingStatus);

      await fetchUserData(token);

      return notify("success", message);
    } catch (error) {
      // Clear token on error
      logout();
      return notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred. Please try again."
      );
    }
  };

  // Admin Login function
  const adminLogin = async (email, password) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/admin/login`,
        { email, password }
      );
      // Save the token in local storage and update the context
      const { token, authenticated } = response.data.data; // Assuming your response structure has token
      localStorage.setItem("loginToken", token);
      localStorage.setItem("role", "admin");
      setIsAdmin(true);
      setLoginToken(token);

      if (authenticated) {
        await fetchUserData(token); // Fetch user data after setting the token
      }

      return notify("success", "Login successful!");
    } catch (error) {
      // Clear token on error
      localStorage.removeItem("loginToken");
      setLoginToken(null);
      return notify(
        "error",
        error.response && error.response.data.error
          ? error.response.data.error
          : "An unexpected error occurred. Please try again."
      );
    }
  };

  // Partner Login function
  const partnerLogin = async (email, password) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/partner/login`,
        { email, password }
      );
      // Save the token in local storage and update the context
      const { token, authenticated, passwordSetted } = response.data.data;
      localStorage.setItem("passwordSetted", passwordSetted);
      localStorage.setItem("loginToken", token);
      localStorage.setItem("role", "partner");
      setIsPartner(true);
      setLoginToken(token);
      if (authenticated) {
        await fetchUserData(token);
      }
      // Add redirect logic based on passwordSetted
      if (!passwordSetted) {
        window.location.href = "/change-password";
        return;
      }

      return notify("success", "Login successful!");
    } catch (error) {
      // Clear token on error
      localStorage.removeItem("loginToken");
      setLoginToken(null);
      return notify(
        "error",
        error.response && error.response.data.error
          ? error.response.data.error
          : "An unexpected error occurred. Please try again."
      );
    }
  };

  // Function to fetch user data (optional)
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/candidates/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOnboardingStatus(response.data.signupStatus);

      dispatch({
        type: "SET_USER_DATA",
        payload: response.data, // Assuming response data contains user data
      });
    } catch (error) {
      console.log(error)
      // return notify("error", "Could not fetch user data. Please try again.");
    }
  };

  const signup = async (userData) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/signup`,
        userData
      );

      const { message } = response.data;
      return notify("success", message);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        error.response.data.errors.forEach((err) => notify("error", err.msg));
      } else if (error.response && error.response.data.message) {
        return notify("error", error.response.data.message);
      } else {
        notify("error", "An unexpected error occurred. Please try again.");
      }
      throw error;
    }
  };

  const resendConfirmationEmail = async (email) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/resendConfirmationEmail`,
        { email }
      );

      return notify(
        "success",
        response.data.message || "Confirmation email resent!"
      );
    } catch (error) {
      return notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error resending confirmation email. Please try again."
      );
    }
  };

  const resendConfirmationPhoneCode = async (email) => {
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/auth/resendConfirmationPhoneCode`,
        { email }
      );

      return notify(
        "success",
        response.data.message || "Phone confirmation code resent!"
      );
    } catch (error) {
      return notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error resending phone confirmation code. Please try again."
      );
    }
  };

  // Confirm the phone code
  const confirmPhoneCode = async (email, phoneCode) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/confirmPhoneCode`,
        { email, confirmationCode: phoneCode }
      );

      notify(
        "success",
        response.data.message || "Phone confirmation successful!"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error confirming the phone code. Please try again."
      );
      throw new Error("Confirmation Code failed");
    }
  };

  // Function to confirm the code
  const confirmEmailCode = async (email, confirmationCode) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/confirmEmailCode`,
        { email, confirmationCode }
      );

      notify("success", response.data.message || "Confirmation successful!");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error confirming the code. Please try again."
      );
      throw new Error("Confirmation Code failed");
    }
  };

  const uploadIdentification = async (front, back) => {
    const token = localStorage.getItem("loginToken");
    try {
      const formData = new FormData();
      formData.append("front", front);
      formData.append("back", back);

      const response = await axios.post(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/identification/uploadIdentification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "Identification uploaded successfully"
      );

      await fetchUserData(token);
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error uploading identification. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to upload identification"
      );
    }
  };

  const updatePersonalInformation = async (
    address,
    country,
    zipCode,
    phoneNumber,
    linkedinUrl
  ) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_BACKEND_URL}/api/candidates/personal-information`,
        {
          address,
          country,
          zipCode,
          phoneNumber,
          linkedinUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      notify(
        "success",
        response.data.message || "Personal information updated successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error updating personal information. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update personal information"
      );
    }
  };  

  const uploadCv = async () => {
    const token = localStorage.getItem("loginToken");
    try {
      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Add a blank page
      pdfDoc.addPage([595, 842]); // A4 size in points (width x height)

      // Serialize the PDF document to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Create a Blob from the PDF bytes
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      // Create a FormData object
      const formData = new FormData();
      formData.append("cv", blob, "blank.pdf"); // Specify the file name as "blank.pdf"

      // Upload the CV
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/uploadCv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify("success", response.data.message || "CV uploaded successfully");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error uploading CV. Please try again."
      );
      throw new Error(error.response?.data?.message || "Failed to upload CV");
    }
  };

  const addSkills = async (skills) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/addSkills`,
        { skills },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify("success", response.data.message || "Skills updated successfully");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding/updating skills. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update skills"
      );
    }
  };

  const addEnglishTestScore = async (score) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/addEnglishTest`,
        { score },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "English test score updated successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding/updating English test score. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update English test score"
      );
    }
  };

  const addSalariesFullTimeAndPosition = async (
    minMonthlySalary,
    maxMonthlySalary,
    fullTime,
    position,
    seniority
  ) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/candidates/addSalariesAndFullTimeAndPosition`,
        { minMonthlySalary, maxMonthlySalary, fullTime, position, seniority },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "Account details updated successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error updating account details. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update account details"
      );
    }
  };

  const getOwnEnglishTestScore = async () => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/tests/english/own`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Assuming the response contains the score in data
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to retrieve English test score"
      );
    }
  };

  const changePassword = async (newPassword, confirmNewPassword) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/candidates/change-password`,
        {
          newPassword,
          confirmNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify(
        "success",
        response.data.message || "Password changed successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response?.data?.message || "Failed to change password"
      );
      throw new Error(
        error.response?.data?.message || "Failed to change password"
      );
    }
  };

  const validateEnglishTestScore = async (certUrl) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/tests/english/validate`,
        { certUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "English test score validated successfully"
      );

      return response.data.data; // Assuming the response contains the validated score in data
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error validating English test score. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to validate English test score"
      );
    }
  };

  const getListOfSkills = async () => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/assessments/skills`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error retrieving list of skills. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to retrieve list of skills"
      );
    }
  };

  const addAssessments = async (assessments) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/assessments/candidate/add-assessments`,
        { assessments },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("success", "Assessments added successfully");
      return response.data;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding assessments. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to add assessments"
      );
    }
  };

  const createCvFromLinkedIn = async (linkedinUrl) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/resumes/create-from-linkedin`,
        { linkedinUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("success", "CV created successfully");
      return response.data;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error creating CV from LinkedIn. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to create CV from LinkedIn"
      );
    }
  };

  const getUserAssessments = async () => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/api/assessments/own`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error retrieving user assessments. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to retrieve user assessments"
      );
    }
  };

  const generatePrivateAssessmentUrl = async (assessment) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${
          import.meta.env.VITE_BACKEND_URL
        }/api/assessments/generate-private-url`,
        { assessment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("success", "Private assessment URL generated successfully");
      return response.data.data.assessmentUrl;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error generating private assessment URL. Please try again."
      );
      throw new Error(
        error.response?.data?.message ||
          "Failed to generate private assessment URL"
      );
    }
  };

  // Check for the current token and update state accordingly on initial load
  useEffect(() => {
    const token = localStorage.getItem("loginToken");
    if (token) {
      setLoginToken(token);
      if (localStorage.getItem("role") !== "partner" || localStorage.getItem("role") !== "admin") {
        fetchUserData(token); // Optionally fetch user data on app load
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        adminLogin,
        partnerLogin,
        logout,
        setLoginToken,
        resendConfirmationEmail,
        signup,
        confirmEmailCode,
        confirmPhoneCode,
        uploadIdentification,
        uploadCv,
        addSkills,
        addEnglishTestScore,
        addSalariesFullTimeAndPosition,
        getOwnEnglishTestScore,
        validateEnglishTestScore,
        getListOfSkills,
        addAssessments,
        createCvFromLinkedIn,
        getUserAssessments,
        generatePrivateAssessmentUrl,
        resendConfirmationPhoneCode,
        updatePersonalInformation,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
