import React, { useContext, useEffect } from "react";
import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../context";


const Header = () => {
  const { logout, userData } = useContext(AuthContext);

  return (
    <Box bg="white" color="black" padding="20px">
      <Flex justify="space-between" align="center" pr={2}>
        <Flex align="center" pl={2}>
          <Image src="/images/logo_blue.png" alt="Company Logo" boxSize="40px" mr={2} />
          <Text fontSize="xl" color="#016DDA" fontWeight={600} as={RouterLink} to="/">
            MyTeamAroad
          </Text>
        </Flex>

        <Flex align="center" pr={4}>
          <Text fontSize="lg" mr={3} fontWeight={600}>
          {userData ? userData.firstName : ""} {userData ? userData.lastName : ""} 
          </Text>
          <Link onClick={()=>logout()} fontSize="lg" color="blue.500" fontWeight={600}>
            Logout
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;

