import React, { useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  Input,
  Select,
  Stack,
  HStack,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context";
import positions from "../../../data/positions";

const JobDetails = () => {
  const { addSalariesFullTimeAndPosition, userData } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    },
  });

  useEffect(() => {
    reset({
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    });
  }, [userData, reset]);

  const onSubmit = async (data) => {
    const { minMonthlySalary, maxMonthlySalary, modality, position, seniority } = data;
    const fullTime = modality === "full-time";

    try {
      await addSalariesFullTimeAndPosition(
        minMonthlySalary,
        maxMonthlySalary,
        fullTime,
        position,
        seniority
      );
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Flex
      pb={5}
      px={5}
      pt={2}
      borderRadius="12px"
      backgroundColor="white"
      width="100%"
      flex="1"
      direction="column"
    >
      <Text color="#49424D" fontSize={22} fontWeight={700} mb={3}>
        Update Job Details{" "}
      </Text>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1}>
            <FormControl isRequired>
              <Text my={1} color="#000F2B">
                Desired monthly range
              </Text>
              <HStack>
                <Input
                  type="number"
                  {...register("minMonthlySalary", {
                    required: "Minimum salary is required",
                    min: {
                      value: 0,
                      message: "Minimum salary must be positive",
                    },
                  })}
                  placeholder="USD"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  color="#000F2B"
                  opacity={0.65}
                  _placeholder={{ color: "#000F2B", opacity: 0.65 }}
                />
                <Text color="#000F2B" opacity={0.65}>
                  To
                </Text>
                <Input
                  type="number"
                  placeholder="USD"
                  {...register("maxMonthlySalary", {
                    required: "Maximum salary is required",
                    validate: (value) =>
                      value >
                        parseFloat(
                          document.getElementsByName("minMonthlySalary")[0]
                            ?.value
                        ) ||
                      "Maximum salary must be greater than minimum salary",
                  })}
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  color="#000F2B"
                  opacity={0.65}
                  _placeholder={{ color: "#000F2B", opacity: 0.65 }}
                />
              </HStack>
              {errors.minMonthlySalary && (
                <Text color="red">{errors.minMonthlySalary.message}</Text>
              )}
              {errors.maxMonthlySalary && (
                <Text color="red">{errors.maxMonthlySalary.message}</Text>
              )}
            </FormControl>

            <FormControl isRequired>
              <Text my={1} color="#000F2B">
                Position
              </Text>
              <Select
                {...register("position", { required: "Position is required" })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
                color="#000F2B"
                opacity={0.65}
              >
                <option value="">Select a position</option>
                {positions.map((position, index) => (
                  <option key={index} value={position}>
                    {position}
                  </option>
                ))}
              </Select>
              {errors.position && (
                <Text color="red">{errors.position.message}</Text>
              )}
            </FormControl>

            <FormControl isRequired>
              <Text my={1} color="#000F2B">
                Modality
              </Text>
              <Select
                {...register("modality", { required: "Modality is required" })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
                color="#000F2B"
                opacity={0.65}
              >
                <option value="">Select a modality</option>
                <option value="part-time">Part-Time</option>
                <option value="full-time">Full-Time</option>
              </Select>
              {errors.modality && (
                <Text color="red">{errors.modality.message}</Text>
              )}
            </FormControl>

            <FormControl isRequired>
              <Text my={1} color="#000F2B">
                Seniority
              </Text>
              <Select
                {...register("seniority", { required: "Seniority is required" })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
                color="#000F2B"
                opacity={0.65}
              >
                <option value="">Select a seniority</option>
                <option value="Junior">Junior</option>
                <option value="MidLevel">MidLevel</option>
                <option value="Senior">Senior</option>
              </Select>
              {errors.seniority && (
                <Text color="red">{errors.seniority.message}</Text>
              )}
            </FormControl>
          </Stack>
          <Button
            type="submit"
            borderRadius="8px"
            background="#016DDA"
            color="white"
            _hover={{ background: "#016DDA" }}
            mt={4}
            fontWeight={400}
            width="100%"
          >
            Save
          </Button>
        </form>
      </Box>
    </Flex>
  );
};

export default JobDetails;