import React, { useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  HStack,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context";
import positions from "../../data/positions";

const DesiredSalary = () => {
  const { addSalariesFullTimeAndPosition, userData, login, loginToken } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    },
  });

  useEffect(() => {
    reset({
      minMonthlySalary: userData?.minMonthlySalary || "",
      maxMonthlySalary: userData?.maxMonthlySalary || "",
      position: userData?.position || "",
      modality: userData?.fullTime ? "full-time" : "part-time",
      seniority: userData?.seniority || "",
    });
  }, [userData, reset]);

  const onSubmit = async (data) => {
    const { minMonthlySalary, maxMonthlySalary, modality, position, seniority } = data;
    const fullTime = modality === "full-time";

    try {
      await addSalariesFullTimeAndPosition(
        minMonthlySalary,
        maxMonthlySalary,
        fullTime,
        position,
        seniority
      );
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box
      p={5}
      borderRadius="12px"
      background="white"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Minimum Payment</FormLabel>
            <HStack>
              <Input
                type="number"
                {...register("minMonthlySalary", {
                  required: "Minimum salary is required",
                  min: {
                    value: 0,
                    message: "Minimum salary must be positive",
                  },
                })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
              <Text>to</Text>
              <Input
                type="number"
                {...register("maxMonthlySalary", {
                  required: "Maximum salary is required",
                  validate: (value) =>
                    value >
                      parseFloat(
                        document.getElementsByName("minMonthlySalary")[0]
                          ?.value
                      ) ||
                    "Maximum salary must be greater than minimum salary",
                })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </HStack>
            {errors.minMonthlySalary && (
              <Text color="red">{errors.minMonthlySalary.message}</Text>
            )}
            {errors.maxMonthlySalary && (
              <Text color="red">{errors.maxMonthlySalary.message}</Text>
            )}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Position</FormLabel>
            <Select
              {...register("position", { required: "Position is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a position</option>
              {positions.map((position, index) => (
                <option key={index} value={position}>
                  {position}
                </option>
              ))}
            </Select>
            {errors.position && (
              <Text color="red">{errors.position.message}</Text>
            )}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Modality</FormLabel>
            <Select
              {...register("modality", { required: "Modality is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a modality</option>
              <option value="part-time">Part-Time</option>
              <option value="full-time">Full-Time</option>
            </Select>
            {errors.modality && (
              <Text color="red">{errors.modality.message}</Text>
            )}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Seniority</FormLabel>
            <Select
              {...register("seniority", { required: "Seniority is required" })}
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a seniority</option>
              <option value="Junior">Junior</option>
              <option value="MidLevel">MidLevel</option>
              <option value="Senior">Senior</option>
            </Select>
            {errors.seniority && (
              <Text color="red">{errors.seniority.message}</Text>
            )}
          </FormControl>
        </Stack>
        <Flex justifyContent="flex-end" mt={4} px={4}>
          <Button
            type="submit"
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default DesiredSalary;