// components/LoginProcess/Skills.jsx

import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  Stack,
  HStack,
  IconButton,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "axios";
import notify from "../../../utils/notify"; // Import your notify function

const Skills = ({ handleNext, getListOfSkills, assessments }) => {
  const [skills, setSkills] = useState([]);

  const { register, handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      skills: [], // Initialize as empty
      selectedSkill: "", // Initialize selectedSkill
    },
  });

  // useFieldArray to dynamically manage the skills array
  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  // Extract skill IDs from assessments for filtering
  const assessmentSkillIds = useMemo(() => {
    return assessments.map((assessment) => assessment.skillId);
  }, [assessments]);

  // Fetch the list of skills when the component mounts
  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const skillsList = await getListOfSkills();
        setSkills(skillsList);
      } catch (error) {
        notify(
          "error",
          error.response && error.response.data.message
            ? error.response.data.message
            : "Error retrieving list of skills. Please try again."
        );
      }
    };

    fetchSkills();
  }, [getListOfSkills]);

  // Function to add assessments
  const addAssessments = async (assessmentIds) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/assessments/candidate/add-assessments`,
        { assessments: assessmentIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      notify("success", "Assessments added successfully");
      return response.data;
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding assessments. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to add assessments"
      );
    }
  };

  // Filter out skills that have already been added or are in assessments
  const availableSkills = useMemo(() => {
    const selectedSkillIds = fields.map((skill) => skill.id);
    return skills.filter(
      (skill) =>
        !selectedSkillIds.includes(skill.id) &&
        !assessmentSkillIds.includes(skill.id)
    );
  }, [fields, skills, assessmentSkillIds]);

  // Handle form submission
  const onSubmit = async (data) => {
    // Extract the list of assessment IDs from the selected skills
    const assessmentIds = data.skills.map((skill) => skill.id);

    try {
      await addAssessments(assessmentIds); // Add assessments using the IDs
      handleNext(); // Proceed to the next step after adding assessments
    } catch (error) {
      console.error("Error adding assessments:", error);
      notify(
        "error",
        error.message || "Error adding assessments. Please try again."
      );
    }
  };

  // Handle adding the selected skill by ID
  const handleAddSkill = (skillId) => {
    if (!skillId) return; // Prevent adding if no skill is selected

    if (fields.length >= 10) {
      // Notify the user if they try to add more than 10 skills
      notify("error", "You cannot add more than 10 skills");
      return;
    }

    const selectedSkill = skills.find((skill) => skill.id === skillId);
    if (selectedSkill) {
      append({ skillName: selectedSkill.name, ...selectedSkill });
      setValue("selectedSkill", ""); // Reset the skill selection after adding
    }
  };

  // Function to format skill names with optional seniority
  const formatSkillName = (skill) => {
    if (!skill || !skill.name) return "";
    const nameWithoutPrefix = skill.name.startsWith("MTA - ")
      ? skill.name.replace("MTA - ", "")
      : skill.name;
    return skill.seniority
      ? `${nameWithoutPrefix} - ${skill.seniority}`
      : nameWithoutPrefix;
  };

  return (
    <Box px={64}>
      <Heading fontSize={40}>Skills</Heading>
      <Text color="#707887" mt={4} mb={8}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem.
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} p={0}>
          {fields.length > 0 && (
            <HStack wrap="wrap" spacing={2}>
              {fields.map((skill, index) => (
                <HStack key={skill.id} spacing={0}>
                  <Button
                    size="sm"
                    variant="outline"
                    borderRadius="8px 0 0 8px"
                    background="#E8E9E9"
                    color="#596375"
                  >
                    {formatSkillName(skill)}
                  </Button>
                  <IconButton
                    aria-label="Remove Skill"
                    size="sm"
                    icon={<CloseIcon fontSize={8} />}
                    onClick={() => remove(index)}
                    borderRadius="0 8px 8px 0"
                    background="#E8E9E9"
                    borderLeft="none"
                    pr={4}
                  />
                </HStack>
              ))}
            </HStack>
          )}
          <HStack spacing={4}>
            <FormControl>
              <Select
                {...register("selectedSkill")}
                placeholder="Select a skill"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              >
                {availableSkills.map((skill) => (
                  <option key={skill.id} value={skill.id}>
                    {formatSkillName(skill)}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Button
              type="button"
              px={8}
              py={6}
              onClick={() =>
                handleAddSkill(control._formValues.selectedSkill)
              }
              sx={{
                borderRadius: "8px",
                background: "#DCEBFA",
                color: "#016DDA",
                textAlign: "center",
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "normal",
                _hover: {
                  background: "#B9DBF5",
                  color: "#005BB5",
                },
              }}
            >
              Add Skill +
            </Button>
          </HStack>
        </Stack>
        <Flex justifyContent="flex-end" mt={8} px={0}>
          <Button
            px={7}
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
            type="submit"
          >
            Next
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default Skills;