import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PartnerLayout from "../../components/Partners/PartnersLayout";
import { Box, Button, Progress } from "@chakra-ui/react";
import TableCandidates from "../../components/Partners/TableCandidates";
import { PartnerCandidates } from "../../components/Partners/Views/Candidates";
import { Customize } from "../../components/Partners/Views/Customize";
import AnalyticsDashboard from "../../components/Partners/Views/Market";
import { fetchCandidatesData, fetchPartnerCompany } from "../../context/partnetContext";
import RevenueFormPartners from "../../components/Partners/RevenueFormPartners";

interface CandidateStats {
  totalCandidates: number;
  availableCandidates: number;
  salaryRanges: {range: string; count: number; percentage: number}[];
  countriesCount: {country: string; count: number; percentage: number}[];
  topSkills: {name: string; count: number; percentage: number}[];
}

const calculateCandidateStats = (candidates: any[], countryClicked: string): CandidateStats => {
  if (!candidates) return {
    totalCandidates: 0,
    availableCandidates: 0,
    salaryRanges: [],
    countriesCount: [],
    topSkills: []
  };

  // Filtrar candidatos por país si se ha seleccionado uno
  const filteredCandidates = countryClicked === 'None' 
    ? candidates 
    : candidates.filter(c => c.country === countryClicked);

  // Contar candidatos disponibles del país seleccionado
  const availableCandidates = filteredCandidates.filter(c => c.active).length;
  const totalCandidates = filteredCandidates.length;

  // Procesar skills de los candidatos filtrados
  const skillsMap = new Map<string, number>();
  
  filteredCandidates.forEach(candidate => {
    if (candidate.skills && Array.isArray(candidate.skills)) {
      candidate.skills.forEach((skill: { name: string }) => {
        const skillName = skill.name;
        skillsMap.set(skillName, (skillsMap.get(skillName) || 0) + 1);
      });
    }
  });

  // Convertir el mapa de skills a array y calcular porcentajes
  const topSkills = Array.from(skillsMap.entries())
    .map(([name, count]) => ({
      name,
      count,
      percentage: totalCandidates > 0 
        ? Math.round((count / totalCandidates) * 100)
        : 0
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);

  // Procesar rangos de salarios de los candidatos filtrados
  const salaryRanges = [
    { range: '0-1000', count: 0, percentage: 0 },
    { range: '1000-2000', count: 0, percentage: 0 },
    { range: '2000-3000', count: 0, percentage: 0 },
    { range: '3000-4000', count: 0, percentage: 0 },
    { range: '4000+', count: 0, percentage: 0 }
  ];

  filteredCandidates.forEach(candidate => {
    const minSalary = Number(candidate.minMonthlySalary);
    const maxSalary = Number(candidate.maxMonthlySalary);
    const avgSalary = (minSalary + maxSalary) / 2;

    if (avgSalary <= 1000) salaryRanges[0].count++;
    else if (avgSalary <= 2000) salaryRanges[1].count++;
    else if (avgSalary <= 3000) salaryRanges[2].count++;
    else if (avgSalary <= 4000) salaryRanges[3].count++;
    else salaryRanges[4].count++;
  });

  // Calcular porcentajes para los rangos de salario
  salaryRanges.forEach(range => {
    range.percentage = totalCandidates > 0 
      ? Math.round((range.count / totalCandidates) * 100)
      : 0;
  });

  // Contar por países (mantenemos todos los países para el contexto)
  const countriesMap = new Map<string, number>();
  candidates.forEach(candidate => {
    const country = candidate.country;
    countriesMap.set(country, (countriesMap.get(country) || 0) + 1);
  });
  
  const countriesCount = Array.from(countriesMap.entries())
    .map(([country, count]) => ({
      country,
      count,
      percentage: candidates.length > 0 
        ? Math.round((count / candidates.length) * 100) 
        : 0
    }))
    .sort((a, b) => b.count - a.count);

  return {
    totalCandidates,
    availableCandidates,
    salaryRanges,
    countriesCount,
    topSkills
  };
};

const PartnerPage = () => {
  const [candidateData, setCandidateData] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [countryClicked, setCountryClicked] = useState('None');

  const token = localStorage.getItem("loginToken");
  const location = useLocation();
  const fragment = location.hash;

  const param = fragment.substring(1);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchCandidatesData(token);
      if ('data' in response) {
        setCandidateData(response.data?.candidates);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompany = async () => {
    const response = await fetchPartnerCompany(token);
    setCompanyData(response);
  };

  useEffect(() => {
    fetchData();
    fetchCompany();
  }, []);
  
  const stats = candidateData ? calculateCandidateStats(candidateData, countryClicked) : null;

  return (
    <PartnerLayout>
      {(!param || param === "Candidates") && (
        <Box>
          <PartnerCandidates stats={stats} />
          <Box bg="white" marginTop="2em" borderRadius={10} padding={10}>
            <TableCandidates />
          </Box>
        </Box>
      )}
      {param === "Customize-CVs" && <Customize fetchCompany={fetchCompany} companyData={companyData} />}
      {param === "Market-Data" && <AnalyticsDashboard stats={stats} setCountryClicked={setCountryClicked}/>}
      {param === "revenue" && <RevenueFormPartners />}
    </PartnerLayout>
  );
};

export default PartnerPage;
