const positions = [
  "Software Engineer",
  "Data Scientist",
  "Web Developer",
  "DevOps Engineer",
  "Project Manager",
  "UX/UI Designer",
  "QA Engineer",
  "Systems Analyst",
  "Product Manager",
  "Business Analyst",
  "Cloud Engineer",
  "Machine Learning Engineer",
  "Full Stack Developer",
  "Network Engineer",
  "Information Security Analyst",
  "Technical Writer",
  "Database Administrator",
  "Game Developer",
  "Mobile Developer",
  "Salesforce Developer",
  "Frontend Developer",
  "Backend Developer",
  "AI Engineer",
  "Digital Marketing Specialist",
  "SEO Specialist",
  "Content Strategist",
  "Technical Support Engineer",
  "Research Scientist",
  "Compliance Analyst",
  "IT Consultant",
  "Systems Architect",
  "Business Intelligence Analyst",
  "Cloud Solutions Architect",
  "E-commerce Specialist",
  "Social Media Manager",
  "Web Designer",
  "Game Designer",
  "CRM Developer",
  "Blockchain Developer",
  "Data Engineer",
  "Robotics Engineer",
  "IT Project Coordinator",
  "Voice User Interface (VUI) Designer",
  "Sales Engineer",
  "Ethical Hacker",
  "Technical Recruiter",
  "Change Manager",
];

export default positions;
