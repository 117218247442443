import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  Image,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const IDCheck = ({ uploadIdentification, fetchUserData, loginToken }) => {
  const [frontID, setFrontID] = useState(null);
  const [backID, setBackID] = useState(null);
  const navigate = useNavigate();

  const handleFrontIDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFrontID(file);
    }
  };

  const handleBackIDChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackID(file);
    }
  };

  const sendIDCheck = async () => {
    try {
      await uploadIdentification(frontID, backID);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Box py={5} px={64}>
      <Heading fontSize={34} mb={4}>
        ID Check
      </Heading>
      <Text fontSize={16} color="#707887">
        We will fill your MyTeam Abroad MTA CV with your LinkedIn profile, so
        please make sure your LinkedIn profile is accurate before submitting
        this URL.
      </Text>

      <Flex justify="space-between" width="100%" gap={10} mt={9}>
        <FormControl isRequired>
          <Flex align="center" mb={4} width="100%" direction="column">
            <Input
              type="file"
              accept="image/*"
              onChange={handleFrontIDChange}
              display="none"
              id="front-id-input"
            />
            <Button
              onClick={() => document.getElementById("front-id-input").click()}
              background="transparent"
              border="1px solid #016DDA"
              color="#016DDA"
              width="100%"
              borderRadius="8px"
              py={6}
            >
              Upload Front
            </Button>

            <Image
              src={
                frontID ? URL.createObjectURL(frontID) : "images/front_id.png"
              }
              alt="Front of ID"
              width="100%"
              mt={6}
            />
          </Flex>
        </FormControl>

        <FormControl isRequired>
          <Flex align="center" width="100%" direction="column">
            <Input
              type="file"
              accept="image/*"
              onChange={handleBackIDChange}
              display="none"
              id="back-id-input"
            />
            <Button
              onClick={() => document.getElementById("back-id-input").click()}
              background="transparent"
              border="1px solid #016DDA"
              color="#016DDA"
              width="100%"
              borderRadius="8px"
              py={6}
            >
              Upload Back
            </Button>
            <Image
              src={backID ? URL.createObjectURL(backID) : "images/back_id.png"}
              alt="Back of ID"
              width="100%"
              mt={6}
            />
          </Flex>
        </FormControl>
      </Flex>

      <Flex justifyContent="flex-end" mt={6} px={0}>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#016DDA",
            color: "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#004AA4" },
          }}
          onClick={() => {
            if (frontID && backID) {
              sendIDCheck();
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default IDCheck;