export type TreeNode = {
    type: 'node';
    value: number;
    name: string;
    children: Tree[];
  };
  export type TreeLeaf = {
    type: 'leaf';
    name: string;
    value: number;
  };
  
  export type Tree = TreeNode | TreeLeaf;
  
  export const data: Tree = {
    type: 'node',
    name: "boss",
    value: 0,
    children: [
      {type: 'leaf', name:"JS", value: 90},
      {type: 'leaf', name:"Phyton", value: 12},
      {type: 'leaf', name:"Java", value: 34},
      {type: 'leaf', name:"C#", value: 53},
      {type: 'leaf', name:"PHP", value: 98},
      {type: 'leaf', name:"C++", value: 22},
      {type: 'leaf', name:"C", value: 12},
      {type: 'leaf', name:"Go", value: 45},
      {type: 'leaf', name:"Swift", value: 76}]
  }
  
  export const dataSalary: Tree = {
    type: 'node',
    name: "boss",
    value: 0,
    children: [
      {type: 'leaf', name:"Venezuela", value: 90},
      {type: 'leaf', name:"France", value: 22},
      {type: 'leaf', name:"Spain", value: 34},
      {type: 'leaf', name:"Italy", value: 53},
      {type: 'leaf', name:"Germany", value: 98},
      {type: 'leaf', name:"Netherlands", value: 52},
      {type: 'leaf', name:"Luxembourg", value: 76}]
  }

  export const dataPie = [
    {name:"15", value: 50},
    {name:"10", value: 12},
    {name:"5", value: 34},
]

export const dataHistogram = [
    75.0,
    104.0,
    369.0,
    300.0,
    92.0,
    64.0,
    171.0,
    103.0,
    122.0,
    230.0,
    68.0,
    230.0,
    80.0,
    115.0,
    103.0,
    80.0,
    75.0,
    195.0,
    1034.0,
    87.0,
    103.0,
    92.0,
    63.0,
    98.0,
    149.0,
    172.0,
    63.0,
    74.0,
    276.0,
    71.0,
    76.0,
    109.0,
    98.0,
    138.0,
    107.0,
    201.0,
    106.0,
    459.0,
    80.0,
    327.0,
    34.0,
    115.0,
    132.0,
    57.0,
    803.0,
    92.0,
    69.0,
    96.0,
    69.0,
    75.0,
    109.0,
    52.0,
    50.0,
    63.0,
    143.0,
    78.0,
    175.0,
    67.0,
    113.0,
    87.0,
    98.0,
    344.0,
    660.0,
    103.0,
    58.0,
    103.0,
    92.0,
    69.0,
    90.0,
    230.0,
    46.0,
    163.0,
    127.0,
    58.0,
    85.0,
    114.0,
    81.0,
    143.0,
    58.0,
    76.0,
    122.0,
    190.0,
    138.0,
    97.0,
    62.0,
    81.0,
    1011.0,
    92.0,
    115.0,
    79.0,
    57.0,
    91.0,
    73.0,
    63.0,
    74.0,
    92.0,
    75.0,
    63.0,
    127.0,
    139.0,
    50.0,
    1436.0,
    52.0,
    72.0,
    74.0,
    53.0,
    293.0,
    138.0,
    1609.0,
    92.0,
    80.0,
    63.0,
    403.0,
    112.0,
    146.0,
    67.0,
    569.0,
    98.0,
    83.0,
    46.0,
    127.0,
    113.0,
    334.0,
    80.0,
    196.0,
    115.0,
    46.0,
    18.0,
    29.0,
    80.0,
    30.0,
    34.0,
    29.0,
    23.0,
    23.0,
    26.0,
    26.0,
    40.0,
    37.0,
    23.0,
    29.0,
    32.0,
    80.0,
    24.0,
    40.0,
    32.0,
    40.0,
    23.0,
    48.0,
    115.0,
    25.0,
    172.0,
    29.0,
    43.0,
    27.0,
    33.0,
    30.0,
    57.0,
    58.0,
    287.0,
    345.0,
    1380.0,
    3056.0,
    1069.0,
    1187.0,
    426.0,
    196.0,
    247.0,
    80.0,
    149.0,
    179.0,
    230.0,
    103.0,
    41.0,
    60.0,
    172.0,
    58.0,
    483.0,
    108.0,
    40.0,
    119.0,
    78.0,
    52.0,
    328.0,
    68.0,
    104.0,
    57.0,
    58.0,
    58.0,
    69.0,
    252.0,
    87.0,
    155.0,
    53.0,
    172.0,
    192.0,
    114.0,
    172.0,
    53.0,
    103.0,
    39.0,
    217.0,
    40.0,
    74.0,
    75.0,
    58.0,
    52.0,
    92.0,
    75.0,
    80.0,
    80.0,
    114.0,
    172.0,
    109.0,
    184.0,
    115.0,
    175.0,
    138.0,
    138.0,
    100.0,
    92.0,
    143.0,
    205.0,
    115.0,
    91.0,
    115.0,
    267.0,
    98.0,
    148.0,
    127.0,
    103.0,
    231.0,
    79.0,
    173.0,
    109.0,
    156.0,
    138.0,
    149.0,
    92.0,
    207.0,
    106.0,
    236.0,
    156.0,
    92.0,
    109.0,
    109.0,
    143.0,
    75.0,
    94.0,
    115.0,
    334.0,
    98.0,
    69.0,
    40.0,
    241.0,
    72.0,
    63.0,
    98.0,
    230.0,
    379.0,
    74.0,
    207.0,
    173.0,
    58.0,
    109.0,
    79.0,
    237.0,
    80.0,
    58.0,
    115.0,
    53.0,
    34.0,
    87.0,
    138.0,
    79.0,
    172.0,
    44.0,
    92.0,
    72.0,
    80.0,
    65.0,
    93.0,
    461.0,
    115.0,
    57.0,
    80.0,
    69.0,
    85.0,
    56.0,
    104.0,
    58.0,
    60.0,
    196.0,
    69.0,
    138.0,
    85.0,
    63.0,
    78.0,
    87.0,
    80.0,
    77.0,
    92.0,
    172.0,
    137.0,
    62.0,
    52.0,
    138.0,
    68.0,
    242.0,
    87.0,
    57.0,
    53.0,
    55.0,
    1092.0,
    75.0,
    173.0,
    80.0,
    213.0,
    184.0,
    149.0,
    115.0,
    321.0,
    218.0,
    132.0,
    139.0,
    126.0,
    230.0,
    115.0,
    132.0,
    6322.0,
    689.0,
    172.0,
    149.0,
    103.0,
    75.0,
    56.0,
    459.0,
    92.0,
    81.0,
    92.0,
    804.0,
    148.0,
    138.0,
    345.0,
    448.0,
    518.0,
    137.0,
    173.0,
    97.0,
    271.0,
    2874.0,
    114.0,
    64.0,
    167.0,
    75.0,
    138.0,
    115.0,
    172.0,
    172.0,
    138.0,
    138.0,
    103.0,
    345.0,
    127.0,
    172.0,
    172.0,
    104.0,
    172.0,
    207.0,
    204.0,
    87.0,
    190.0,
    103.0,
    265.0,
    156.0,
    613.0,
    87.0,
    80.0,
    102.0,
    922.0,
    253.0,
    133.0,
    92.0,
    143.0,
    121.0,
    922.0,
    74.0,
    1149.0,
    68.0,
    445.0,
    115.0,
    632.0,
    80.0,
    508.0,
    207.0,
    231.0,
    172.0,
    138.0,
    115.0,
    224.0,
    114.0,
    202.0,
    149.0,
    104.0,
    193.0,
    160.0,
    56.0,
    207.0,
    143.0,
    103.0,
    92.0,
    299.0,
    109.0,
    58.0,
    173.0,
    114.0,
    138.0,
    87.0,
    69.0,
    87.0,
    103.0,
    103.0,
    104.0,
    218.0,
    80.0,
    92.0,
    172.0,
    404.0,
    138.0,
    149.0,
    127.0,
    264.0,
    209.0,
    69.0,
    225.0,
    1321.0,
    92.0,
    29.0,
    69.0,
    225.0,
    35.0,
    173.0,
    71.0,
    127.0,
    98.0,
    689.0,
    144.0,
    173.0,
    167.0,
    134.0,
    103.0,
    127.0,
    231.0,
    403.0,
    92.0,
    213.0,
    69.0,
    529.0,
    98.0,
    184.0,
    75.0,
    90.0,
    114.0,
    63.0,
    293.0,
    89.0,
    92.0,
    75.0,
    62.0,
    98.0,
    173.0,
    45.0,
    287.0,
    46.0,
    517.0,
    460.0,
    58.0,
    127.0,
    132.0]
