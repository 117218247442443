import { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { changePartnerStatus, deletePartner, fetchPartnersData, registerPartner } from "../../../context/adminContext";
import notify from "../../../utils/notify";
import validator from "email-validator";

interface Partner {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  active: boolean;
}

interface SortConfig {
  key: string;
  direction: 'asc' | 'desc' | null;
}

export const useTablePartners = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState<Partner[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [partnerData, setPartnerData] = useState<Partner[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: null
  });

  const itemsPerPage = 5;
  const pagesVisited = pageNumber * itemsPerPage;
  const token = localStorage.getItem("loginToken");

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data: any) => {
    try {
      const {
        email,
        firstName,
        lastName,
      } = data;

      if (!email || !firstName || !lastName) {
        return notify("error", "Please fill in all required fields.");
      }

      if (!validator.validate(email)) {
        return notify("error", "The email is not valid. Please enter a valid email.");
      }

      await registerPartner({
        email,
        firstName,  
        lastName,
      }, token);

      fetchData();
      onClose();
      notify("success", "Partner registered successfully");
    } catch (error) {
      notify("error", "An error occurred during signup. Please try again later.");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchPartnersData(token);
      if ('data' in response) {
        setFilteredData(response.data?.partners);
        setPartnerData(response.data?.partners);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (userId: string) => {
    await deletePartner(token, userId);
    fetchData();
  };

  const handleChangeStatus = async (userId: string, active: boolean) => {
    await changePartnerStatus(token, userId, active);
    fetchData();
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    filterData(keyword, statusFilter);
  };

  const handleStatusFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value;
    setStatusFilter(status);
    filterData("", status);
  };

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' | null = 'asc';
    
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = null;
      }
    }

    setSortConfig({ key, direction });
    if (direction === null) {
      setFilteredData(partnerData);
      return;
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (key === 'firstName') {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return direction === 'asc' 
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
      if (key === 'email') {
        return direction === 'asc'
          ? a.email.localeCompare(b.email)
          : b.email.localeCompare(a.email);
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const filterData = (searchKeyword: string, status: string) => {
    let filtered = partnerData;
    
    if (status !== "all") {
      const isActive = status === "active";
      filtered = filtered.filter((item) => item.active === isActive);
    }

    if (searchKeyword) {
      filtered = filtered.filter((item) =>
        `${item.firstName} ${item.lastName}`.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    }

    if (sortConfig.direction) {
      filtered = [...filtered].sort((a, b) => {
        if (sortConfig.key === 'firstName') {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          return sortConfig.direction === 'asc'
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        }
        if (sortConfig.key === 'email') {
          return sortConfig.direction === 'asc'
            ? a.email.localeCompare(b.email)
            : b.email.localeCompare(a.email);
        }
        return 0;
      });
    }

    setFilteredData(filtered);
  };

  const downloadData = filteredData?.map(({ id, ...rest }) => rest);

  return {
    isOpen,
    onOpen,
    onClose,
    filteredData,
    pagesVisited,
    itemsPerPage,
    loading,
    error,
    showPassword,
    showConfirmPassword,
    statusFilter,
    sortConfig,
    togglePasswordVisibility,
    toggleConfirmPasswordVisibility,
    onSubmit,
    handleDelete,
    handleChangeStatus,
    handleSearch,
    handleStatusFilter,
    handleSort,
    downloadData
  };
}; 