export const AuthReducer = (state, action) => {
    switch (action.type) {        
      case "SET_LOGIN_TOKEN":
        return {
          ...state,
          loginToken: action.payload,
        };
      case "SET_IS_ADMIN":
        return {
          ...state,
          isAdmin: action.payload,
        };
      case "SET_IS_PARTNER":
        return {
          ...state,
          isPartner: action.payload,
        };
      case "SET_USER_DATA":
        return {
          ...state,
          userData: action.payload,
        };
      case "SET_ONBOARDING_STATUS":
      return {
        ...state,
        onboardingStatus: action.payload,
      };
      case "CLEAR_STATE":
        return {
          loginToken: null,
          userData: null,
        };
  
      default:
        return state;
    }
  };
