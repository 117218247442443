import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

const EnglishTest = ({
  handleNext,
  validateEnglishTestScore,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await validateEnglishTestScore(data.certUrl);
      handleNext();
    } catch (error) {}
  };

  return (
    <Box py={5} px={60}>
      <Heading fontSize={34} size="lg" mb={4}>
        English Test
      </Heading>
      <Text fontSize={16} color="#707887" mb={4}>
        Please take the English test at the following link:{" "}
        <a
          href="https://www.efset.org/ef-set-50/launch/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#016DDA" }}
        >
          https://www.efset.org/ef-set-50/launch/
        </a>
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="certUrl">Certificate URL</FormLabel>
          <Input
            id="certUrl"
            placeholder="Enter your certificate URL"
            {...register("certUrl", {
              required: "Certificate URL is required",
            })}
          />
          {errors.certUrl && <Text color="red">{errors.certUrl.message}</Text>}
        </FormControl>
        <Flex justifyContent="flex-end" mt={6} px={0}>
          <Button
            px={6}
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
            type="submit"
          >
            Validate
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EnglishTest;
