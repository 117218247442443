import React from "react";
import { Box, Button, Progress, SimpleGrid } from "@chakra-ui/react";
import { getCountryCode } from "../../../utils/countries";

export const PartnerCandidates = ({ stats }: { stats: any }) => {
  return (
    <Box 
      display="flex" 
      
      flexDirection={{ base: "column", lg: "row" }}
      alignItems={{ base: "stretch", lg: "center" }}
      gap="24px"
      // px={{ base: "16px", md: "24px" }}
    >
      {/* Stats Cards */}
      <SimpleGrid 
        columns={{ base: 2, md: 2, lg: 1 }}
        spacing="45px"
        w={{ base: "100%", lg: "214px" }}
      >
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
        >
          <Button
            w={"32px"}
            h={"32px"}
            size="sm"
            borderRadius="8px"
            bg="#016DDA"
            color="#FFFFFF"
            padding={0}
          >
            <img src="/public/Icons/ui-id.svg" />
          </Button>
          <Box display="flex" flexDirection="column">
            <Box>Total Candidates</Box>
            <Box fontSize="24px" fontWeight="bold">
              {stats?.totalCandidates}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
        >
          <Button
            w={"32px"}
            h={"32px"}
            size="sm"
            borderRadius="8px"
            bg="#016DDA"
            color="#FFFFFF"
            padding={0}
          >
            <img src="/public/Icons/ui-bell.svg" />
          </Button>
          <Box display="flex" flexDirection="column">
            <Box>Active Candidates</Box>
            <Box fontSize="24px" fontWeight="bold">
              {stats?.availableCandidates}
            </Box>
          </Box>
        </Box>
      </SimpleGrid>

      {/* Charts Grid */}
      <SimpleGrid 
        columns={{ base: 1, md: 2, xl: 3 }}
        spacing="24px"
        w="100%"
      >
        {/* SKILLS */}
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
          minH="303px"
          gap="5px"
        >
          <Box fontSize="24px" fontWeight="bold">
            Top Skills
          </Box>
          <Box w="100%" display="flex" flexDirection="column">
            {stats?.topSkills?.map((skill) => (
              <Box key={skill.name} w="100%" display="flex" flexDirection="column">
                <Box>{skill.name}</Box>
                <Box display="flex" alignItems="center" gap="10px">
                  <Progress w="100%" borderRadius="50px" value={skill.percentage} />
                  <Box display="flex" alignItems="center" gap="5px">
                    <Box color="#808080">{skill.count}</Box>
                    <Box fontWeight="bold">{skill.percentage}%</Box>
                  </Box>
                </Box>  
              </Box>
            ))}
          </Box>
        </Box>

        {/* SALARY */}
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
          minH="303px"
          gap="5px"
        >
          <Box fontSize="24px" fontWeight="bold">
            Salary Expectation
          </Box>
          <Box w="100%" display="flex" flexDirection="column">
            <Box w="100%" display="flex" flexDirection="column">
              <Box>0-1000$</Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[0].percentage} />
                <Box display="flex" alignItems="center" gap="5px">
                  <Box color="#808080">{stats?.salaryRanges[0].count}</Box>
                  <Box fontWeight="bold">{stats?.salaryRanges[0].percentage}%</Box>
                </Box>
              </Box>
            </Box>
            <Box w="100%" display="flex" flexDirection="column">
              <Box>1000-2000$</Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[1].percentage} />
                <Box display="flex" alignItems="center" gap="5px">
                  <Box color="#808080">{stats?.salaryRanges[1].count}</Box>
                  <Box fontWeight="bold">{stats?.salaryRanges[1].percentage}%</Box>
                </Box>
              </Box>
            </Box>
            <Box w="100%" display="flex" flexDirection="column">
              <Box>2000-3000$</Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[2].percentage} />
                <Box display="flex" alignItems="center" gap="5px">
                  <Box color="#808080">{stats?.salaryRanges[2].count}</Box>
                  <Box fontWeight="bold">{stats?.salaryRanges[2].percentage}%</Box>
                </Box>
              </Box>
            </Box>
            <Box w="100%" display="flex" flexDirection="column">
              <Box>3000-4000$</Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[3].percentage} />
                <Box display="flex" alignItems="center" gap="5px">
                  <Box color="#808080">{stats?.salaryRanges[3].count}</Box>
                  <Box fontWeight="bold">{stats?.salaryRanges[3].percentage}%</Box>
                </Box>
              </Box>
            </Box>
            <Box w="100%" display="flex" flexDirection="column">
              <Box>4000$+</Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[4].percentage} />
                <Box display="flex" alignItems="center" gap="5px">
                  <Box color="#808080">{stats?.salaryRanges[4].count}</Box>
                  <Box fontWeight="bold">{stats?.salaryRanges[4].percentage}%</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* TOP COUNTRIES */}
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="10px"
          bg="white"
          w="100%"
          minH="303px"
          gap="5px"
        >
          <Box fontSize="24px" fontWeight="bold">
            Top Countries
          </Box>
          <Box w="100%" display="flex" flexDirection="column" gap="9px">
            {stats?.countriesCount
              ?.sort((a: any, b: any) => b.count - a.count)
              .slice(0, 4)
              .map((country: any) => (
                <Box
                  key={country.country}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Box w={50}>
                      <img src={`/public/images/flags/${getCountryCode(country.country)}.svg`} />
                    </Box>
                    <Box>{country.country}</Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap="10px">
                    {country.count} <b>{country.percentage}%</b>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
