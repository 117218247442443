"use client"

import React, { useState } from 'react';
import { Box, Grid, Heading, useColorModeValue, GridItem, Button, Progress } from "@chakra-ui/react"
import { VectorMap } from "@south-paw/react-vector-maps";
import * as world from "../../../utils/world.json";
import styled from "styled-components"
import { Treemap } from './components/TreeMap';
import { data, dataHistogram, dataPie, dataSalary } from './components/data';
import { PieChart } from './components/PieChart';
import { Histogram } from './components/Histogram';
import { getCountryCode } from '../../../utils/countries';

const MapContainer = styled.div<{ countries: any; }>`
  margin: 1rem auto;
  width: 100%;

  svg {
    path {
      fill: #012257;
      cursor: pointer;
      outline: none;

      &:hover {
        fill: #4ba6d6;
      }

      &:focus {
        fill: #4ba6d6;
      }

      &[aria-checked="true"] {
        fill: rgba(56, 43, 168, 1);
      }

      &[aria-current="true"] {
        fill: rgba(56, 43, 168, 0.83);
      }
      
      ${({ countries }) =>
        countries?.map(
          (country) => `
          &[id="${getCountryCode(country.country).toLowerCase()}"] {
            fill: rgba(56, 43, 168, 0.6);
          }
        `
        ).join('') || ''}
    }
  }
`;

export default function AnalyticsDashboard({ stats, setCountryClicked }: { stats: any, setCountryClicked: any }) {
  const bgColor = useColorModeValue("white", "gray.800")
  const borderColor = useColorModeValue("gray.200", "gray.700")
  const [currentLayer, setCurrentLayer] = React.useState(world);

  const layerProps = {
    onClick: ({ target }) => handleClick(target.attributes.name.value),
  };

  const handleClick = (country: string) => {
    console.log(country);
    setCountryClicked(country);
  };

  console.log(stats);


  const calculateAvailableCandidatesPercentage = () => {
    if (stats?.totalCandidates > 0) {
      return ((stats.availableCandidates / stats.totalCandidates) * 100).toFixed(2);
    }
    return 0;
  };

  return (
    <Box>
      <Box width={'100%'} background={'white'} display="flex" alignItems="center" padding={'24px'} borderRadius={'10px'} mb={6} fontSize={'20px'} fontWeight={'700'}>
        Candidates
      </Box>
      <Box mb={6}>
        <Grid
          h='460px'
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(5, 1fr)'
          gap={4}
          w="100%"
        >
          <GridItem colSpan={3} rowSpan={2}>
              <Grid
              templateRows='repeat(2, 1fr)'
              templateColumns='repeat(6, 1fr)'
              gap={4}
              w="100%"
              h="100%"
            >
              <GridItem colSpan={2}>
                <Box
                  display="flex"
                  padding="24px"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderRadius="10px"
                  bg="white"
                  w="100%"
                >
                  <Button
                    w={"32px"}
                    h={"32px"}
                    size="sm"
                    borderRadius="8px"
                    bg="#016DDA"
                    color="#FFFFFF"
                    padding={0}
                  >
                    <img src="/public/Icons/ui-id.svg" />
                  </Button>
                  <Box display="flex" flexDirection="column">
                    <Box>Total Registrations</Box>
                    <Box fontSize="24px" fontWeight="bold">
                      {stats?.totalCandidates}
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <Box
                  display="flex"
                  padding="24px"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderRadius="10px"
                  bg="white"
                  w="100%"
                >
                  <Button
                    w={"32px"}
                    h={"32px"}
                    size="sm"
                    borderRadius="8px"
                    bg="#016DDA"
                    color="#FFFFFF"
                    padding={0}
                  >
                    <img src="/public/Icons/ui-id.svg" />
                  </Button>
                  <Box display="flex" flexDirection="column">
                    <Box>Pased</Box>
                    <Box fontSize="24px" fontWeight="bold">
                      {stats?.availableCandidates}
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <Box
                  display="flex"
                  padding="24px"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderRadius="10px"
                  bg="white"
                  w="100%"
                >
                  <Button
                    w={"32px"}
                    h={"32px"}
                    size="sm"
                    borderRadius="8px"
                    bg="#016DDA"
                    color="#FFFFFF"
                    padding={0}
                  >
                    <img src="/public/Icons/ui-bell.svg" />
                  </Button>
                  <Box display="flex" flexDirection="column">
                    <Box>Approval Rate</Box>
                    <Box fontSize="24px" fontWeight="bold">
                      {calculateAvailableCandidatesPercentage()}%
                    </Box>
                  </Box>
                </Box>
              </GridItem>
            
            <GridItem colSpan={3}>
              <Box
                display="flex"
                padding="20px"
                flexDirection="column"
                alignItems="flex-start"
                borderRadius="10px"
                bg="white"
                minH="303px"
                gap="5px"
              >
                <Box fontSize="24px" fontWeight="bold">
                  Top Skills
                </Box>
                <Box w="100%" display="flex" flexDirection="column">
                  {stats?.topSkills?.map((skill) => (
                    <Box key={skill.name} w="100%" display="flex" flexDirection="column">
                      <Box>{skill.name}</Box>
                      <Box display="flex" alignItems="center" gap="10px">
                        <Progress w="100%" borderRadius="50px" value={skill.percentage} />
                        <Box display="flex" alignItems="center" gap="5px">
                          <Box fontSize="14px" color="#808080">{skill.count}</Box>
                          <Box fontSize="14px" fontWeight="bold">{skill.percentage}%</Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={3}>
              <Box
                display="flex"
                padding="20px"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                borderRadius="10px"
                bg="white"
                w="100%"
                minH="303px"
              >
                <Box fontSize="24px" fontWeight="bold">
                  Salary Expectation
                </Box>
                <Box w="100%" display="flex" flexDirection="column">
                  <Box w="100%" display="flex" flexDirection="column">
                    <Box>0-1000$</Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[0].percentage} />
                      <Box display="flex" alignItems="center" gap="5px">
                        <Box fontSize="14px" color="#808080">{stats?.salaryRanges[0].count}</Box>
                        <Box fontSize="14px" fontWeight="bold">{stats?.salaryRanges[0].percentage}%</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w="100%" display="flex" flexDirection="column">
                    <Box>1000-2000$</Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[1].percentage} />
                      <Box display="flex" alignItems="center" gap="5px">
                        <Box fontSize="14px" color="#808080">{stats?.salaryRanges[1].count}</Box>
                        <Box fontSize="14px" fontWeight="bold">{stats?.salaryRanges[1].percentage}%</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w="100%" display="flex" flexDirection="column">
                    <Box>2000-3000$</Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[2].percentage} />
                      <Box display="flex" alignItems="center" gap="5px">
                        <Box fontSize="14px" color="#808080">{stats?.salaryRanges[2].count}</Box>
                        <Box fontSize="14px" fontWeight="bold">{stats?.salaryRanges[2].percentage}%</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w="100%" display="flex" flexDirection="column">
                    <Box>3000-4000$</Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[3].percentage} />
                      <Box display="flex" alignItems="center" gap="5px">
                        <Box fontSize="14px" color="#808080">{stats?.salaryRanges[3].count}</Box>
                        <Box fontSize="14px" fontWeight="bold">{stats?.salaryRanges[3].percentage}%</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box w="100%" display="flex" flexDirection="column">
                    <Box>4000$+</Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Progress w="100%" borderRadius="50px" value={stats?.salaryRanges[4].percentage} />
                      <Box display="flex" alignItems="center" gap="5px">
                        <Box fontSize="14px" color="#808080">{stats?.salaryRanges[4].count}</Box>
                        <Box fontSize="14px" fontWeight="bold">{stats?.salaryRanges[4].percentage}%</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </GridItem>
            </Grid>
            
          </GridItem>
          <GridItem display="flex" justifyContent="center" alignItems="center" rowSpan={8} colSpan={2} bg='white' borderRadius="10px" padding="24px">
            <MapContainer countries={stats?.countriesCount}>
              <VectorMap {...currentLayer} layerProps={layerProps}/>
            </MapContainer>
          </GridItem>
        </Grid>
      </Box>
        <Grid h='400px'
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(6, 1fr)'
        gap={4}
        w="100%">
          <GridItem colSpan={2} rowSpan={2}>
            <Box bg={bgColor} p={6} borderRadius="lg" borderWidth={1} borderColor={borderColor}>
              <Heading size="md" mb={4}>Candidates Passed by Technology</Heading>
              <Box>
                <Treemap data={data} width={400} height={300} />
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={2} rowSpan={2}>
            <Box bg={bgColor} p={6} borderRadius="lg" borderWidth={1} borderColor={borderColor}>
              <Heading size="md" mb={4}>Seniority Distribution</Heading>
              <PieChart data={dataPie} width={400} height={295} />
            </Box>
          </GridItem>
          <GridItem colSpan={2} rowSpan={2}>
            <Box bg={bgColor} p={6} borderRadius="lg" borderWidth={1} borderColor={borderColor}>
              <Heading size="md" mb={4}>Average Salary per Country</Heading>
              <Box>
                <Treemap data={dataSalary} width={400} height={300} />
              </Box>
            </Box>
          </GridItem>
        </Grid>
        <GridItem colSpan={3} rowSpan={2}>
          <Box bg={bgColor} p={6} borderRadius="lg" borderWidth={1} borderColor={borderColor}>
            <Heading size="md" mb={4}>Salary Expectation</Heading>
            <Histogram width={1300} height={300} data={dataHistogram} />
          </Box>
        </GridItem>
    </Box>
  )
}

