import Swal from "sweetalert2";

export default function notify(type, menssage, timer = 3000) {
    return Swal.mixin({
        icon: type,
        title: menssage,
        position: "top-end",
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    }).fire();
}