import React, { useContext } from "react";
import { Box, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { AuthContext } from "../../context";

const LinkBox: React.FC<{ children: React.ReactNode; href: string }> = ({
  children,
  href,
}) => {
  return (
    <Link
      _hover={{
        borderRadius: "10px",
        background: "#4A58EC",
      }}
      href={href}
    >
      <Box
        display="flex"
        padding="8px 16px"
        alignItems="center"
        gap={4}
        alignSelf="stretch"
        _hover={{
          borderRadius: "10px",
          background: "#4A58EC",
          color: "#fff",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const Sidebar: React.FC = () => {
  return (
    <Box
      position="fixed"
      padding={"30px 18px"}
      left={0}
      top={0}
      bottom={0}
      width={230}
      bg="#fff"
      zIndex={11}
    >
      <img src="/public/images/large-logo.svg" width="90%" alt="Logo" />
      <Box marginTop={"40px"}>
        <Box marginTop={2}>
          <LinkBox href={`partner#Candidates`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Candidates</Box>
          </LinkBox>
        </Box>
        <Box marginTop={2}>
          <LinkBox href={`partner#Market-Data`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Market Data</Box>
          </LinkBox>
        </Box>
        <Box marginTop={2}>
          <LinkBox href={`partner#Customize-CVs`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Customize CVs</Box>
          </LinkBox>
        </Box>
        <Box marginTop={2}>
          <LinkBox href={`partner#revenue`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Revenue</Box>
          </LinkBox>
        </Box>
      </Box>
    </Box>
  );
};

const Navbar: React.FC = () => {
  const { logout } = useContext(AuthContext);

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height={24}
      bg="#ffffff"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      paddingRight={20}
      zIndex={10}
    >
      <Menu>
        <MenuButton display={"flex"} gap={'8px'} cursor={"pointer"} as={Box}>
          <Box fontSize={"16px"} fontWeight={"600"} display={"flex"} alignItems={"center"} gap={"8px"}>
            <img src="/public/images/logo-min-blue.svg" width="24px" alt="Logo" />
            Partner
          </Box>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const PartnerLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box className="layout" bg="#F5F5F5" width="100vw" minHeight="100vh">
      <Sidebar />
      <Box marginLeft={214} marginTop={24} padding={4}>
        <Navbar />
        <Box padding={35}>
          <Box padding={"24px"} borderRadius={10}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerLayout;
